<template>
    <div>
        <Header/>
        <div class="fixingTop"></div>
        <Banner/>

        <h1 class="h1Height">Livraison</h1>
        <h2 class="soustitre">Voici les informations demandées pour la livraison.</h2>
        <div v-if="this.order">
          <v-row>
            <v-col cols="12" md="9">
              <v-row class="grid-pad titleStyle">
                <v-col cols="12" md="6">
                  <v-row class="gridHeader">
                    <h2 class="gridTitleStyle">Information du destinataire :</h2>                   
                  </v-row>
                  <div class="mt-5">
                    <v-row class="txt-l">
                      <v-col cols="12"><h3 :class="{'error': error.deleveryOn == true }" >Date de livraison</h3></v-col>
                      <v-col cols="12"><input v-if="order" :class="{'error': error.deleveryOn == true }" @change="error.deleveryOn = false" style="height: 27px;" type="Date" class="Datepicker" :min="$filters.YYYYMMDD(ShippingDate.min)" :valueAsDate="order.deliveryOn" @input="order.deliveryOn = $event.target.value"></v-col>
                      <v-col cols="12" v-if="error.deleveryOn == true"><p class="errorText">Une date de livraison est requise <span  v-if="error.deleveryMinDate == true">ou la date minimale n'est pas respecter</span> **</p></v-col>
                    </v-row>
                    <v-row class="mt-5">
                      <v-col cols="12"><h3 :class="{'error': error.name == true }" >Nom</h3></v-col>
                      <v-col cols="12" v-if="error.name == true"><p class="errorText">Un nom est requis **</p></v-col>
                      <v-col cols="12"><v-text-field :class="{'error': error.name == true }" @change="error.name = false" v-model="order.recipient.name" clearable required /></v-col> <!--:rules="nameRules"-->
                    </v-row>
                    <v-row>
                      <v-col cols="12"><h3 :class="{'error': error.phone == true }" >Téléphone</h3></v-col>
                      <v-col cols="12" v-if="error.phone == true"><p class="errorText">Un téléphone est requis **</p></v-col>
                      <v-col cols="12"><phone v-model:item="order.recipient.phone" @change="error.phone = false" clearable :required="true"></phone></v-col> <!--:rules="nameRules"-->
                    </v-row>
                    <v-row>
                      <v-col cols="12"><h3>Occasion</h3></v-col>
                      <v-col cols="12"><v-select v-model="order.options" :items="['Naissance','Anniversaire','Prompt rétablissement','Décès','Autres']" clearable required /></v-col> <!--:rules="nameRules"-->
                    </v-row>
                    <v-row class="">
                        <v-col cols="12"><h3>Indication spéciale pour la livraison</h3></v-col>
                        <v-col cols="12"><v-textarea v-model="order.comment"  clearable required /></v-col> <!--:rules="nameRules"-->
                    </v-row>
                  </div>

                </v-col>
                <v-col cols="12" md="6" class="">
                  <v-row class="gridHeader">
                    <v-col cols="12"><h2 :class="{'error': error.shippingDistance == true }" class="gridTitleStyle">Livré à :</h2></v-col>
                    <v-col cols="12" v-if="error.shippingDistance == true"><p class="errorText">**Vous ne respectez pas la distance de livraison**</p></v-col>              
                  </v-row>
                  <div class="shippingSpacing">
                    <v-row>
                      <v-col cols="12" class="txt-r">
                        <div class="" v-if="order.shippingAddress">
                          <v-btn @click="this.setAddress('Invoice')" :class="{'v-secondary': order.shippingAddress.typeId == 1}" class="btnShipping"><v-icon icon="mdi-home"></v-icon><span class="btnShippingText">Même adresse</span></v-btn>
                          <v-btn @click="this.setAddress('Shipping')" :class="{'v-secondary': order.shippingAddress.typeId == 2}" class="btnShipping"><v-icon icon="mdi-google-maps"></v-icon><span class="btnShippingText">Autre adresse</span></v-btn>
                          <v-btn @click="this.setAddress('Pickup'); " :class="{'v-secondary': order.shippingAddress.typeId == 3}" class="btnShipping"><v-icon class="pr-1" icon="mdi-domain"></v-icon><span class="btnShippingText">Ramassage</span></v-btn>
                          <v-select class="mt-8" v-if="this.order.shippingAddress.typeId == 3" v-model="homeAddress" :items="['Drummondville']" required />
                          <h3 v-if="this.order.shippingAddress.typeId == 3">Prévoir un délai de 3 heures pour la préparation de la commande.</h3>
                        </div>
                      </v-col>
                    </v-row>
                    <div v-if="order.shippingAddress">
                      <v-row :class="{'gray': !(order.shippingAddress.typeId == 2)}">
                        <v-col class="mt-5" cols="12"><h3 :class="{'error': error.street == true }">Adresse</h3></v-col>
                        <v-col cols="12" v-if="error.street == true"><p class="errorText">Une Adresse est requise **</p></v-col>
                        <v-col cols="12"><v-text-field :class="{'error': error.street == true }" @change="changeAddress('street')" v-model="order.shippingAddress.street"  :readonly="!(order.shippingAddress.typeId == 2)" /></v-col>
                      </v-row>
                      <v-row :class="{'gray': !(order.shippingAddress.typeId == 2)}">
                        <v-col cols="12"><h3 :class="{'error': error.city == true }">Ville</h3></v-col>
                        <v-col cols="12" v-if="error.city == true"><p class="errorText">Une ville est requise **</p></v-col>
                        <v-col cols="12"><v-text-field :class="{'error': error.city == true }" @change="changeAddress('city')" v-model="order.shippingAddress.city.name"  :readonly="!(order.shippingAddress.typeId == 2)" /></v-col> <!--:rules="nameRules"-->
                      </v-row>
                      <v-row :class="{'gray': !(order.shippingAddress.typeId == 2)}">
                        <v-col cols="12"><h3>Province</h3></v-col>
                        <v-col cols="12"><v-select v-model="order.shippingAddress.city.state.name" :items="['Québec','Ontario','Colombie-Britanique','L’Île-du-Prince-Édouard','Manitoba','Nouveau-Brunswick','Nouvelle-Écosse','Alberta','Saskatchewan','Terre-Neuve-et-Labrador']" :readonly="!(order.shippingAddress.typeId == 2)" /></v-col> <!--:rules="nameRules"-->
                      </v-row>
                      <v-row :class="{'gray': !(order.shippingAddress.typeId == 2)}">
                        <v-col cols="12"><h3 :class="{'error': error.zipCode == true }" >Code Postal</h3></v-col>
                        <v-col cols="12" v-if="error.zipCode == true"><p class="errorText">Un code postal est requis **</p></v-col>
                        <v-col cols="12"><v-text-field :class="{'error': error.zipCode == true }" @change="changeAddress('zipCode')"  v-model="order.shippingAddress.zipCode" :readonly="!(order.shippingAddress.typeId == 2)" /></v-col> <!--:rules="nameRules"-->
                      </v-row>
                    </div>

                  </div>

                </v-col>
              </v-row>
              <v-row class="grid-pad titleStyle">
                <v-col cols="12">
                  <h2 :class="{'error': error.comment == true }" class="gridTitleStyle">Item de livraison :</h2>
                  <v-col cols="12" v-if="error.comment == true"><p class="errorText">Chaque item doit avoir un message ou cocher la case aucun message **</p></v-col>
                  <v-row v-for="(item, i) in this.order.items" :key="i" style="" class="ItemsBox pa-5">
                    <v-col cols="12" sm="2" class="">
                      <div  class="divItemsImg verticalCenter"><img v-if="item.url" class="itemsImg" :src="item.url" :truesrc="item.url"/> <img v-else class="itemsImg" :src="this.image" :truesrc="this.image"/></div>
                    </v-col>

                    <v-col cols="12" sm="10" class="">
                      <div ><p class="itemTitle">{{item.description}}</p><p class="ItemsDescription">[{{ item.number }}]</p></div>
                        <v-row>
                            <v-col cols="12"><v-checkbox class="txt-l"  :model-value="item.comment == '[Aucun Message]'" @change="checkboxChange(i,$event)" label="Aucun Message"></v-checkbox> </v-col>
                            <v-col cols="12"><v-textarea class="px-2" id="itemComment" placeholder="Message d'accompagnement + signature" @change="error.comment = false" v-model="item.comment"  :readonly="item.comment == '[Aucun Message]'" /></v-col>
                        </v-row>
                    </v-col>

                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" class="rel">
              <Summary step="shippingInfo"  v-model:item="order" v-model:dirty="isDirty" @eCommerceNext="Updated" @eCommerceBack="back"/>
            </v-col>
          </v-row>
        </div>
        <Footer/>
    </div>
</template>
<style>
    #itemComment {max-height: 75px !important;}
</style>
<style scoped>
    /*dialog*/
    .dialogBox { width: 50% !important; height: 50%; margin: auto auto !important;}
    .dialogCard {padding:18px !important;}
    .dialogSubtitle {font-weight: 700; margin: 10px 0px !important;}
    .dialogMessage {padding: 18px !important; max-height: 100px; scroll-behavior: smooth; overflow-y:scroll; background-color: rgba(var(--secondary),0.3); overflow-x:hidden;}
    .h1Height {height:180px;}
    h3 {text-align: left; margin-bottom: 5px !important;}
    .ItemsBox {position:relative; color: rgba(var(--primary));  height: auto; background-color: #FFF; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); margin: 20px 20px 30px 20px !important; padding: 10px !important;}
    .divItemsImg {height: 90px; width: 90px; border-radius: 50%; background-color: black; display: inline-block; margin: 0px 0px 0px 15px !important;}
    .itemsImg {width:100%; height:100%; border-radius: 50%;}
    .verticalCenter{position: absolute; transform: translate(-70%, 40%);}
    .titleStyle { text-align: center;}
    .gridTitleStyle {text-align:left; margin-top:8px !important;}
    .gridHeader {height: 44px}
    .itemTitle {font-family: Ephesis; text-align: left; font-size: 2em; display: inline;}
    .btnShipping {padding: 4px !important; margin: 4px !important;}
    .shippingSpacing { padding-left: 20px !important; margin-top: 40px !important; }
    .soustitre {padding-left: 100px !important;}
    .ItemsDescription {padding-left: 10px !important; display: inline; font-size: 14px;}
    @media handheld, only screen and (max-width: 600px) {
      .btnShipping {margin: 4px !important; width: 100%;}
      .verticalCenter{ position: relative; top: -20%; transform: none; text-align: center; display: block; margin-left: auto !important; margin-right: auto !important; }
      .ItemsBox {padding: 40px 0px 0px 0px !important; margin: 20px 20px 30px 20px !important; }
    }
    @media handheld, only screen and (max-width: 960px) {
      .shippingSpacing { padding-left: 0px !important; }
      .h1Height {height:auto;}
      .soustitre {padding: 20px 20px !important; font-size: 32px;}
      h2{font-size: 18px;}
      h3{font-size: 14px;}
      .dialogBox {width: 80% !important; margin-left: auto !important; margin-right: auto !important;}
    }
    @media handheld, only screen and (max-width: 1120px) {
      
    }
    @media handheld, only screen and (max-width: 1350px) {
      .btnShippingText {font-size: 0.75em;}
    }
    
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import Summary from '@/components/infoPayment.vue'
import phone from '@/components/Commons/Phone.vue';
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';



export default {
  name: 'Event',
  mixins: [apiECommerceExtension],
  props: {},
  data: () => ({
    order:null,
    homeAddress: 'Drummondville',
    otherShippingAdresse: false,
    livraison: 'Invoice', //Invoice, Shipping, Pickup
    DefaultAddress: {},
    validationFinal:{
      valid: false,
      error: [],
    },
    ShippingDate:{min:""},
    image: 'https://abstracxionstorage.blob.core.windows.net/fbergeron/products/Default.jpg',
    isDirty: false,
    error:{
      name: false,
      phone: false,
      deleveryOn: false,
      deleveryMinDate: false,
      shippingDistance: false,
      street: false,
      city: false,
      zipCode: false,
      comment: false,
    },
  }),
  watch: {
    livraison: function() {
      // Fonction appelée lorsqu'une modification est détectée
      if(this.isDirty == false){this.isDirty = true;}
    },
    homeAddress: function(){
      if(this.order.shippingAddress.typeId = 3) {
          if(this.homeAddress == 'Drummondville'){
            this.order.shippingAddress.street = '1380 Rue Hébert';
            this.order.shippingAddress.city.name = 'Drummondville';
            this.order.shippingAddress.city.state.name = 'Québec';
            this.order.shippingAddress.city.state.country.name = 'Canada';
            this.order.shippingAddress.zipCode = 'J2C 1Z9';
            this.order.shippingAddress.typeId = 3;
            this.livraison == 'Pickup';
          }
        }
    },

  },
  mounted: async function () {
    //console.log('Mounted...');
    this.order = await this.GetOrder();

    let DA = await this.GetDefaultAddress();
    this.DefaultAddress = JSON.parse(JSON.stringify(DA));
    if(this.order.shippingAddress != null){

      if(this.order.shippingAddress.typeId == 2) { this.setAddress('Shipping');} 
      else if(this.order.shippingAddress.typeId == 3){this.setAddress('Pickup');}
      else{this.setAddress('Invoice');}

    } else { this.setAddress('Invoice'); }

    this.setDate();
    this.validation();
  },
  methods:{
    validation: async function(){
      this.validationFinal.valid = true;
      this.validationFinal.error = [];

      let valid = false;
      this.order.deliveryOn = new Date(this.order.deliveryOn);
      let order = await this.GetOrder();
      let validation = order.validations;
      let errorFlag = {name: false, phone: false, email: false, street: false, city: false, zipCode: false, comment: false, shippingDistance: false, };

      if(validation){
        valid = true;
        validation.forEach(e => {
          if(e.severity == 1){
            if(e.field == "DeliveryOn") {
              if(e.code == "Valid"){
                // let myDate = new Date(e.message);
                // let offsetMinutes = myDate.getTimezoneOffset();
                // this.ShippingDate.min = new Date(myDate.setMinutes(myDate.getMinutes() + offsetMinutes ));

                let dt = new Date(e.message);
                dt.setHours(12,0,0);
                this.ShippingDate.min = dt;

              }
            }
          }
          if(e.severity == 3){
            if(e.field == "Recipient.Name") { valid = false; errorFlag.name = true; }
            else if(e.field == "Recipient.Phone.Number") { valid = false; errorFlag.phone = true; }
            else if(e.field == "DeliveryOn") {
              valid = false;
              errorFlag.deleveryOn = true;
              if(e.code == "Underflow"){
                errorFlag.deleveryMinDate = true;
                // let myDate = new Date();
                // let offsetMinutes = myDate.getTimezoneOffset();
                // this.ShippingDate.min = new Date(myDate.setMinutes(myDate.getMinutes() + offsetMinutes ));

                let dt = new Date();
                dt.setHours(12,0,0);
                this.ShippingDate.min = dt;
              }
            }
            else if(e.field == "Address.Street") { valid = false; errorFlag.street = true; }
            else if(e.field == "Address.ZipCode") { valid = false; errorFlag.zipCode = true; }
            else if(e.field == "Address.City.Name") { valid = false; errorFlag.city = true; }
            else if(e.field == "KM") { valid = false; errorFlag.shippingDistance = true; }
            else{
              if(this.validationFinal.valid == true){this.validationFinal.valid = false;}
              this.validationFinal.error.push(e.message);
            }
          }

        });
      }
      // just to wait validation
      this.order.items.forEach(e => { if(!e.comment && e.comment != ""){valid = false; errorFlag.comment = true;}});

      if(valid == false){this.error = errorFlag;}

      return valid;
    },
    Updated: async function() {
      //console.log('updated...');
      if(this.order.deliveryOn){this.order.deliveryOn = new Date(this.order.deliveryOn);}
      let valid = await this.validation();
      if(valid == true){
        if(this.validationFinal.valid == true){this.navigateTo();}
        else{  $App.event.$emit("app_error", this.validationFinal.error); }
      }
      
    },
    navigateTo: function () {
        this.$router.push('/order/summary');
    },
    setAddress: function(s){
      if(!this.order.shippingAddress){this.order.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));}
      if(s == 'Invoice'){
        if(this.order.shippingAddress.typeId != 1){this.isDirty = true;}
        this.order.shippingAddress = JSON.parse(JSON.stringify(this.order.account.address));
        if(this.order.shippingAddress.street){this.error.street = false;}
        if(this.order.shippingAddress.city.name){this.error.city = false;}
        if(this.order.shippingAddress.zipCode){this.error.zipCode = false;}
        this.livraison == 'Invoice'
      }
      else if(s == 'Pickup'){
        if(this.order.shippingAddress.typeId != 3){this.isDirty = true;}
        this.order.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
        this.order.shippingAddress.street = '1380 Rue Hébert';
        this.order.shippingAddress.city.name = 'Drummondville';
        this.order.shippingAddress.city.state.name = 'Québec';
        this.order.shippingAddress.city.state.country.name = 'Canada';
        this.order.shippingAddress.zipCode = 'J2C 1Z9';
        this.order.shippingAddress.typeId = 3;
        this.livraison == 'Pickup'
        this.error.street = false;
        this.error.city = false;
        this.error.zipCode = false;
      }
      else {
        if(this.order.shippingAddress){

          if(this.order.shippingAddress.typeId != 2){
            this.isDirty = true;
            this.order.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
            this.order.shippingAddress.street = '';
            this.order.shippingAddress.city.name = '';
            this.order.shippingAddress.city.state.name = 'Québec';
            this.order.shippingAddress.city.state.country.name = 'Canada';
            this.order.shippingAddress.zipCode = '';
            this.error.street = true;
            this.error.city = true;
            this.error.zipCode = true;
          }

        } else {
          this.isDirty = true;
          this.order.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
          this.order.shippingAddress.street = '';
          this.order.shippingAddress.city.name = '';
          this.order.shippingAddress.city.state.name = 'Québec';
          this.order.shippingAddress.city.state.country.name = 'Canada';
          this.order.shippingAddress.zipCode = '';
          this.error.street = true;
          this.error.city = true;
          this.error.zipCode = true;
        }
        this.order.shippingAddress.typeId = 2;
        this.livraison == 'Shipping'
      }
    },
    setDate: function(){

      if(this.order.deliveryOn) {

        // let orderDate = new Date(this.order.deliveryOn);
        // let myDateHours = myDate.getHours() + 1;
        // this.order.deliveryOn = new Date(orderDate.setHours(myDateHours));

        let dt = new Date(this.order.deliveryOn);
        dt.setHours(12,0,0);
        this.order.deliveryOn = dt;
       
      } 
      else { 
        //new Date(myDate); 
      };

      let dt = new Date();
      dt.setHours(12,0,0);

      this.ShippingDate.min = dt;

    },
    back: function(){this.$router.push('/order/billing');},
    checkboxChange: function(index, event) {
      let isChecked = event.target.checked;
      this.error.comment = false;
      if(isChecked){ this.order.items[index].comment = "[Aucun Message]"; } else { this.order.items[index].comment = null; }
    },
    changeAddress: function(sId){
      this.isDirty = true;
      switch (sId) {
        case 'street':
            this.error.street = false;
          break;
        case 'city':
          this.error.city = false;
          break;
        case 'zipCode':
          this.error.zipCode = false;
          break;
      
        default:
          break;
      }
    }

  },
  components: {
    Header,
    Footer,
    Banner,
    Summary,
    phone,
  },
}
</script>