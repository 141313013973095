<template>
  <div>
    <Header/>
    <div class="fixingTop"></div>
    <Banner/>
    <h1 class="h1Height">Confirmation</h1>
    <h2 class="soustitre">Voici un résumé de votre commande.</h2>
    <div v-if="this.order">
      <v-row>
        <v-col cols="12" md="9" class="mt-10">
          <div class="grid grid-pad">
            <section>
              <h2 class="sectionTitle" v-if="this.order">Items</h2>
              <div class="ItemSummary mt-5" v-if="this.order">
                <v-row  v-for="(item, i) in this.order.items" :key="i" class="grid-pad itemsBox ">
                  <v-row class="pa-5 gridWidth">
                    <v-col cols="12" sm="2" class="">
                      <div  class="divItemsImg verticalCenter"><img v-if="item.url" class="itemsImg" :src="item.url" :truesrc="item.url"/> <img v-else class="itemsImg" :src="this.image" :truesrc="this.image"/></div>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-row>
                        <v-col cols="12" sm="9">
                            <h2 class="ItemsTitle">{{item.description}} <p class="itemNumber">[{{item.number}}]</p></h2>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <div><h2 class="titleStyle texte">{{item.total}}$</h2></div>
                        </v-col>
                        <v-col cols="12"  class="black">
                          <div><p class="">Message:</p></div>
                          <div><p class="CommentTexte">{{item.comment}}</p></div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-row>
              </div>
              <div class="ItemSummary" v-else>
                <h2 class="subtitleSum">Aucun Item dans votre panier</h2>
              </div>
            </section>
            <section>
              <h2 class="sectionTitle mt-10" v-if="this.order">Information</h2>
              <v-row>
                <v-col cols="12" sm="6">
                  <h2 class="titleStyle mt-5">Facturation</h2>
                  <div class="mt-10 grid-pad">
                    <v-row class="info">
                      <v-col cols="12" sm="2" class="title">Nom:</v-col>
                      <v-col cols="12" sm="10" class="texte"><p v-if="order.account.name">{{order.account.name}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Téléphone:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.account.phone.number">{{$filters.phone(order.account.phone.number)}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="3" class="title">Courriel:</v-col>
                      <v-col cols="12" sm="9" class="texte overFlowX"><p v-if="order.account.email.address">{{order.account.email.address}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                    </v-row>
                    <h2 class="infoSectionItem mt-5">Adresse</h2>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Adresse:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.account.address.street">{{order.account.address.street}}</p><p class="" v-else>[Indéfini]</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Ville:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.account.address.city.name">{{order.account.address.city.name}}</p><p class="" v-else>[Indéfini]</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Code Postal:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.account.address.zipCode">{{order.account.address.zipCode}}</p><p class="" v-else>[Indéfini]</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Province:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.account.address.city.state">{{order.account.address.city.state.name}}</p><p class="" v-else>[Indéfini]</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Pays:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.account.address.city.state.country">{{order.account.address.city.state.country.name}}</p><p class="" v-else>[Indéfini]</p></v-col>
                    </v-row>
                  </div>                        
                </v-col>
                <v-col cols="12" sm="6">
                  <h2 class="titleStyle mt-5">Livraison</h2>
                  <div class="mt-10 grid-pad">
                    <div>
                      <v-row class="info">
                        <v-col cols="12" sm="2" class="title">Destinataire:</v-col>
                        <v-col cols="12" sm="10" class="texte"><p v-if="order.recipient.name">{{order.recipient.name}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                      </v-row>
                      <v-row class="info">
                        <v-col cols="12" sm="6" class="title">Date de livraison:</v-col>
                        <v-col cols="12" sm="6" class="texte"><p v-if="order.deliveryOn">{{ $filters.YYYYMMDD(order.deliveryOn) }}</p><p class="error" v-else>À REMPLIR</p></v-col>
                      </v-row>
                      <v-row class="info">
                        <v-col cols="12" sm="6" class="title">Téléphone:</v-col>
                        <v-col cols="12" sm="6" class="texte"><p v-if="order.recipient.phone">{{$filters.phone(order.recipient.phone.number)}}</p><p class="error" v-else>Indéfini</p></v-col>
                      </v-row>
                      <v-row class="info vh">
                        <v-col cols="12" sm="6" class="title">Courriel:</v-col>
                        <v-col cols="12" sm="6" class="texte"><p v-if="order.account.email.address">{{order.account.email.address}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                      </v-row>
                    </div>
                    <h2 class="infoSectionItem mt-5">Adresse</h2>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Adresse:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.shippingAddress.street">{{order.shippingAddress.street}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Ville:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.shippingAddress.city.name">{{order.shippingAddress.city.name}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Code Postal:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.shippingAddress.zipCode">{{order.shippingAddress.zipCode}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Province:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.shippingAddress.city.state.name">{{order.shippingAddress.city.state.name}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                    </v-row>
                    <v-row class="info">
                      <v-col cols="12" sm="6" class="title">Pays:</v-col>
                      <v-col cols="12" sm="6" class="texte"><p v-if="order.shippingAddress.city.state.country.name">{{order.shippingAddress.city.state.country.name}}</p><p class="error" v-else>À REMPLIR</p></v-col>
                    </v-row>
                  </div> 
                </v-col>
              </v-row>
            </section>
            <section>
              <h2 class="sectionTitle mt-10">Indication spéciale pour la livraison</h2>
              <v-row class="info">
                <v-col cols="12" class="px-5"><p v-if="order.comment">{{order.comment}}</p><p class="" v-else>Aucune Mention Spéciale</p></v-col>
              </v-row>
            </section>
            <section id="LegalNotice">
              <h2 class="sectionTitle mt-10">Mention légale</h2>
              <h2 class="titleStyle mt-10">IMPORTANT</h2>
              <div class="grid grid-pad">
                <p>Notre site transactionnel a été conçu pour des commandes locales <span class="titleStyle">SEULEMENT</span> dans un rayon de 40 kilomètres. </p>
                <p>Pour un envoi dans une municipalité extérieure (41km et plus) veuillez effectuer votre commande <span class="titleStyle">UNIQUEMENT</span> par téléphone au 1-877-477-4709. </p>
                <p>Veuillez noter que l'ensemble des articles achetés lors d'une seule et même séance ne pourra être livré qu'à une seule et même adresse. Si vous désirez faire livrer des arrangements à des adresses différentes, vous devez procéder en faisant vos achats séparément.</p>
              </div>
              <h2 class="titleStyle">Termes légaux et conditions</h2>
              <div class="grid grid-pad">
                <p>Veuillez prendre note que Fleuriste Bergeron livre dans un <span class="titleStyle">rayon maximum de 40 kilomètres de Drummondville</span> et que des frais de livraison de 12$ ou plus seront systématiquement ajoutés au montant de votre achat avec utilisation de notre application. Le tarif varie selon la destination.</p>
              </div>
            </section>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="rel">
          <Summary step="paiement" v-model:item="order" @eCommerceCompleted="completeOrder" @eCommerceBack="back" />              
        </v-col>
      </v-row>
      </div>
    <Footer/>
  </div>
</template>
<style scoped>
    .soustitre {padding-left: 100px !important;}
    .h1Height {height:180px;}
    h3{text-align: left; margin-bottom: 5px !important;}
    #LegalNotice p {text-align: justify; margin: 20px 0px !important;}
    .itemsBox {position:relative; color: rgba(var(--primary));  height: 100%; background-color: #FFF; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); margin: 20px 20px 30px 20px !important; padding: 20px 0px 20px 0px !important;}
    .itemsImg {width:100%; height:100%; border-radius: 50%;}
    .divItemsImg {height: 90px; width: 90px; border-radius: 50%; background-color: black; display: inline-block; margin: 0px 0px 0px 15px !important;}
    .verticalCenter{position: absolute; top: 50%; transform: translate(0%, -50%);}
    .ItemsTitle {font-family: Ephesis;}
    .itemNumber {font-family: Arial, Helvetica, sans-serif; color: rgba(var(--secondary)); font-size: small; margin: -30px 0px 0px 10px !important; display: inline-block;}
    .CommentTexte {text-align: center;}
    .titleStyle {font-weight: var(--fw-bold) !important;}
    .sectionTitle { font-size: 48px; font-family: Ephesis; text-align: left; color: rgba(var(--secondary),0.5);}
    .info {padding-top: 5px !important;}
    .infoSectionItem {color: rgba(var(--secondary)); font-weight: 700 !important;}
    .title {font-weight: 700;}
    .texte {text-align: right;}
    .gridWidth {width:100%;}
    .vh {visibility:hidden;}
    .overFlowX {overflow-x: hidden;}
    @media handheld, only screen and (max-width: 600px) {
      .texte {text-align: center;}
      .verticalCenter{ position: relative; top: -20%; transform: none; text-align: center; display: block; margin-left: auto !important; margin-right: auto !important; }
      .ItemsBox {padding: 40px 0px 10px 0px !important;}
      .ItemsTitle {text-align: center;}
    }
    @media handheld, only screen and (max-width: 960px) {
      .soustitre {padding: 20px 20px !important; font-size: 32px;}
      .h1Height {height:auto;}
      h2{font-size: 18px;}
      h3{font-size: 14px;}
    }
    @media handheld, only screen and (max-width: 1264px) {}
    
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import Summary from '@/components/infoPayment.vue'
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';



export default {
  name: 'Commander',
  mixins: [apiECommerceExtension],
  props: {
    summary: Object,
  },
  data: () => ({
    order:null,
    image: 'https://abstracxionstorage.blob.core.windows.net/fbergeron/products/Default.jpg',
  }),
  mounted: async function () {
    this.order = await this.GetOrder();
  },
  methods:{
    completeOrder: async function(InvoiceRef) {
      if(InvoiceRef != null){this.navigateTo(InvoiceRef);}else{alert('erreur dans la procedure')}      
    },
    navigateTo: function (InvoiceRef) {
        let cleared = this.ClearOrder();
        if(cleared == true){ this.$router.push('/invoices/' + InvoiceRef);}        
    },
    back: function(){this.$router.push('/order/shipping');},
  },
  components: {
    Header,
    Footer,
    Banner,
    Summary
  },
}
</script>