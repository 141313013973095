<template>
    <div>
        <Header/>
        <div class="fixingTop"></div>
        <Banner/>
        <h1 class="h1Height">Annulation Paiement</h1>
        <!--Information selon Status-->
        <div v-if="status == 'Pending'" class="grid grid-pad mt-10"> 
            <v-row>
                <h2 class="soustitre">En traitement</h2>
                <v-col cols="12">
                    <v-progress-linear class="" color="rgb(var(--secondary))" indeterminate striped height="8"></v-progress-linear>
                </v-col>
            </v-row>
        </div>
        <div v-if="status == 'Succeed'" class="grid grid-pad mt-10"> 
            <v-row>
                <h2 class="soustitre">La transaction PayPal a été annulée.</h2>
            </v-row>
            <v-row>
                <div class="centeringBtn">

                    <v-btn class="mt-5 orderBtnStyle1">
                        <div >   
                            <h2 @click="navigateToHome()"  class="orderBtnTitleStyle1">Accueil</h2>
                        </div>
                    </v-btn>
                    <v-btn class="mt-5 orderBtnStyle1">
                        <div >   
                            <h2 @click="navigateToInvoice()"  class="orderBtnTitleStyle1">Facture</h2>
                        </div>
                    </v-btn>
                </div>

            </v-row>
        </div>
        <div v-if="status == 'Failed'" class="grid grid-pad mt-10"> 
            <v-row>
                <h2 class="soustitre">Nous vous prions de nous excuser pour l'erreur survenue lors de votre annulation transaction PayPal.</h2>
                <h2 class="soustitre">Si le problème persiste veuillez communique avec un administrateur.</h2>
            </v-row>
            <v-row>
                <div class="centeringBtn">

                    <v-btn class="mt-5 orderBtnStyle1">
                        <div >   
                            <h2 @click="notImplemented()"  class="orderBtnTitleStyle1">Réessayer</h2>
                        </div>
                    </v-btn>
                </div>

            </v-row>
        </div>

        <Footer/>
    </div>
</template>
<style scoped>
    .centeringBtn {display: block; margin-left: auto !important; margin-right: auto !important; margin-top: 20px !important;}
    .soustitre {padding-left: 2% !important;}
    .orderBtnStyle1{margin: 0px 20px !important; padding: 0px 20px !important;}

</style>
<script>
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'
    import Banner from '@/components/Banner.vue'
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';

    export default {
        name: 'Canceled',
        mixins: [apiECommerceExtension],
        data: () => ({
            status:'Pending', //Succeed, Failed, Pending
            tried: 0,
            confirmed: false,
            param: null,
        }),
        watch: {},
        computed: {},
        mounted: async function () {
            this.param = this.$route.params.sid;
            this.transactionCompleted();
        },
        methods:{
            navigateToInvoice: function () { this.$router.push('/invoices/' + this.param); },
            navigateToHome: function () { this.$router.push('/Accueil'); },
            transactionCompleted: async function() {
                if(this.param){
                    this.tried++;
                    let confirmed = await this.PaypalCanceled(this.param);
                    if(confirmed){this.status = 'Succeed';}
                    else{
                        if(this.tried < 2){this.transactionCompleted()} else {this.status = 'Failed';}
                    }
                }else { this.status = 'Failed';}
            },
            notImplemented: function(){
                alert('not implemented');
            }
        },
        components: {
            Header,
            Footer,
            Banner
        },
    }
</script>
