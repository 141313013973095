export const navigateRouteExtension = {
    created: function () {
    },
    methods: {
        navigateOpenNew: function (route) {
            let rte = this.$router.resolve(route);
            window.open(rte.href, '_blank');
        },
        navigateTo: function (route) {
            this.$router.push(route);
        },
        navigateBack: function (route) {
            if (window.history.length > 1) {
                this.$router.go(-1);
            } else if (route) {
                this.$router.push(route);
            }
        }
    }
}