<template>
    <div>
        <Header/>
        <div class="fixingTop"></div>
        <Banner/>

        <h1 class="h1Height">Information</h1>
        <h2 class="soustitre">Voici les Informations demandées pour la facture.</h2>
        <div v-if="this.order">
          <v-row>
            <v-col cols="12" md="9">
              <v-row class="grid-pad titleStyle">
                <v-col cols="12" sm="6">
                  <div>
                    <v-row class="gridHeader">
                        <h2 class="gridTitleStyle">Information de la facture :</h2>                        
                    </v-row>
                  </div>
                  <div class="mt-10">
                    <v-row>
                      <v-col cols="12"><h3 :class="{'error': error.name == true }">Nom</h3></v-col>
                      <v-col cols="12" v-if="error.name == true"><p class="errorText">Un nom est requis **</p></v-col>
                      <v-col cols="12"><v-text-field :class="{'error': error.name == true }" @change="error.name = false" v-model="order.account.name" clearable required /></v-col> <!--:rules="nameRules"-->
                    </v-row>
                    <v-row>
                        <v-col cols="12"><h3 :class="{'error': error.phone == true }">Téléphone</h3></v-col>
                        <v-col cols="12" v-if="error.phone == true" ><p class="errorText">Un téléphone est requis **</p></v-col>
                        <v-col cols="12">
                          <phone v-model:item="order.account.phone" @change="error.phone = false" clearable :required="true" ></phone> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"><h3 :class="{'error': error.email == true }">Courriel</h3></v-col>
                        <v-col cols="12" v-if="error.email == true" ><p class="errorText">Un courriel est requis **</p></v-col>
                        <v-col cols="12"><v-text-field :class="{'error': error.email == true }" @change="error.email = false" v-model="order.account.email.address" clearable required /></v-col> <!--:rules="nameRules"-->
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" class="pdRigthSection">
                  <v-row class="gridHeader">
                    <h2 class="gridTitleStyle">Adresse de Facturation :</h2>
                    <h3 class="warning">Remplir cette section uniquement pour les adresses au Canada.</h3>                  
                  </v-row>
                  <div class=" mt-10">
                    <v-row>
                      <v-col cols="12"><h3 :class="{'warning': error.street == true }">Addresse</h3></v-col>
                      <v-col cols="12" v-if="error.street == true" ><p class="warningText">** Une Addresse serait préférable **</p></v-col>
                      <v-col cols="12"><v-text-field :class="{'warning': error.street == true }" @change="error.street = false && t" v-model="order.account.address.street"  clearable required /></v-col> <!--:rules="nameRules"-->
                    </v-row>
                    <v-row>
                      <v-col cols="12"><h3 :class="{'warning': error.city == true }">Ville</h3></v-col>
                      <v-col cols="12" v-if="error.city == true" ><p class="warningText">** Une ville serait préférable **</p></v-col>
                      <v-col cols="12"><v-text-field :class="{'warning': error.city == true }" @change="error.city = false" v-model="order.account.address.city.name"  clearable required /></v-col> <!--:rules="nameRules"-->
                    </v-row>
                    <v-row>
                      <v-col cols="12"><h3>Province</h3></v-col>
                      <v-col cols="12"><v-select v-model="order.account.address.city.state.name" :items="['Québec','Ontario','Colombie-Britanique','L’Île-du-Prince-Édouard','Manitoba','Nouveau-Brunswick','Nouvelle-Écosse','Alberta','Saskatchewan','Terre-Neuve-et-Labrador']" required/></v-col> <!--:rules="nameRules"-->
                    </v-row>
                    <v-row>
                      <v-col cols="12"><h3>Pays</h3></v-col>
                      <v-col cols="12"><v-select v-model="order.account.address.city.state.country.name" :items="['Canada']" required /></v-col> <!--:rules="nameRules"-->
                    </v-row>
                    <v-row>
                      <v-col cols="12"><h3 :class="{'warning': error.zipCode == true }">Code Postal</h3></v-col>
                      <v-col cols="12" v-if="error.zipCode == true" ><p class="warningText">** Un code postal serait préférable **</p></v-col>
                      <v-col cols="12"><v-text-field :class="{'warning': error.zipCode == true }"  @change="error.zipCode = false" v-model="order.account.address.zipCode" required/></v-col> <!--:rules="nameRules"-->
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" class="rel">
              <Summary step="billingInfo" v-model:item="order" @eCommerceNext="Updated" @eCommerceBack="back"/>
            </v-col>
          </v-row>
        </div>
        <Footer/>
    </div>
</template>
<style scoped>
    .h1Height {height:180px;}
    h3{text-align: left; margin-bottom: 5px !important;}
    .titleStyle {text-align: center;}
    .gridTitleStyle {text-align:left; margin-top:8px !important;}
    .gridHeader {height: 44px}

    .pdRigthSection{padding-left: 20px !important;}
    @media handheld, only screen and (max-width: 600px) {
      .pdRigthSection{padding-left: 0px !important;}
    }
    @media handheld, only screen and (max-width: 960px) {
      .h1Height {height:auto;}
      .soustitre {padding: 20px 20px !important; font-size: 32px;}
      h2{font-size: 18px;}
      h3{font-size: 14px;}
    }
    @media handheld, only screen and (max-width: 1264px) {}
    
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue';
import Summary from '@/components/infoPayment.vue';
import phone from '@/components/Commons/Phone.vue';
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';



export default {
  name: 'Event',
  mixins: [apiECommerceExtension],
  props: {
    summary: Object,
  },
  data: () => ({
    order:null,
    homeAddress: 'Drummondville',
    otherShippingAdresse: false,
    livraison: true,
    DefaultAddress: {},
    ShippingDate:{min:""},
    error:{
      name: false,
      phone: false,
      email: false,
      street: false,
      city: false,
      zipCode: false,
    },

  }),
  watch: {
    livraison: function() {
      // Fonction appelée lorsqu'une modification est détectée
      
      if(this.livraison == false) {
        this.otherShippingAdresse = false;
      }else {
        this.otherShippingAdresse = true;
      }
    },

  },
  computed: {
    otherShippingAdresse: function(){
      if(this.otherShippingAdresse){
        this.order.shippingAddress = this.order.account.address;
      } else {
        this.order.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
        if(this.livraison == false){
          if(this.homeAddress == 'Drummondville'){
            this.order.shippingAddress.street = '1380 Rue Hébert';
            this.order.shippingAddress.city.name = 'Drummondville';
            this.order.shippingAddress.city.state.name = 'Québec';
            this.order.shippingAddress.city.state.country.name = 'Canada';
            this.order.shippingAddress.zipCode = 'J2C 1Z9';
          ;}
          else if(this.homeAddress == 'St-hyacinthe') {
            this.order.shippingAddress.street = '1380 Rue Hébert';
            this.order.shippingAddress.city.name = 'St-hyacinthe';
            this.order.shippingAddress.city.state.name = 'Québec';
            this.order.shippingAddress.city.state.country.name = 'Canada';
            this.order.shippingAddress.zipCode = 'J2C 1Z9';
          }  
        }
      }
    }
  },
  mounted: async function () {
    this.order = await this.GetOrder();
    this.validation();
  },
  methods:{
    validation: async function(){
      let valid = true;
      let validation = this.order.validations;
      let errorFlag = {name: false, phone: false, email: false, street: false, city: false, zipCode: false };
      if(validation){
        validation.forEach(e => {
          if(e.severity == 3){
            if(e.field == "Account.Name") { valid = false; errorFlag.name = true; }
            if(e.field == "Account.Phone.Number") { valid = false; errorFlag.phone = true; }
            if(e.field == "Account.Email.Address") { valid = false; errorFlag.email = true; }
            if(e.field == "Address.Street") { errorFlag.street = true; }
            if(e.field == "Address.ZipCode") { errorFlag.zipCode = true; }
            if(e.field == "Address.City.Name") {errorFlag.city = true; }
          }

        });
        if(valid == false){this.error = errorFlag;}
      }
      return valid;
    },
    Updated: async function() {
      let valid = await this.validation();
      console.log(valid);
      if(valid == true){this.navigateTo();}
      
    },
    navigateTo: function () {
        this.$router.push('/order/shipping');
    },
    back: function(){this.$router.push('/order');},
  },
  components: {
    Header,
    Footer,
    Banner,
    Summary,
    phone,
  },
}
</script>