<template>
    <div>
        <Header/>
        <div class="fixingTop"></div>
        <div class="topBanner">
            <div class="topBannerColor"></div>
        </div>

        <h1 class="pageTitleHeight">Mariage</h1>
        <h2 class="soustitre">Voici parmi nos plus beaux bouquets. Le vôtre défilera peut-être ici.</h2>
        <div class="h-5"></div>
        <v-container fluid>
            <div>
                <v-row class="fixingSize">
                    <v-col cols="12" md="6" class="firstText">
                        <p>Fleuriste Bergeron demeure constamment et depuis toujours au parfum des nouvelles tendances.</p>
                        <p class="mt-5">Chaque bouquet de mariée conçu chez Fleuriste Bergeron est unique et se  doit de refléter votre personnalité. Ainsi ensemble, nous pourrons créer votre bouquet à votre image puisque, tout comme celui-ci, chaque mariée est spéciale et unique.</p>
                        <p class="mt-5">Apportez-nous des photos, des images et des couleurs qui vous allument. Le bouquet de la mariée demeure notre point de départ pour tous les choix subséquents. Et, règle générale, le marié portera à sa boutonnière la fleur dominante du bouquet de sa dulcinée.</p>
                        <p class="mt-5">Pour ce qui est du cortège, plusieurs choix s’offrent à vous. C’est en discutant avec votre fleuriste que vous pourrez déterminer les besoins de chaque personne qui le compose.</p>
                        <p class="mt-5">Du même coup, nous vous renseignerons sur le bon choix de fleurs à employer et celles à éviter. La résistance des fleurs est un point les plus importants puisqu’elles se doivent d’être aussi resplendissantes que vous toute la journée durant.</p>
                    </v-col>
                    <v-col cols="12" md="6" class="secondText">
                        <p>Peu importe votre projet, du plus simple au plus farfelu, nous sommes toujours partant à y collaborer et apporter une touche complémentaire distinctive.</p>
                        <p class="mt-5">Nous conseillons les futurs élus de cœur à prendre rendez-vous avec l’un de nos fleuristes chevronnés de deux à trois mois avant l’heureux évènement.  Cela permet plus de latitude au niveau de la réservation des fleurs désirées et d’y apporter d’éventuels ajustements jugés nécessaires. Certaines fleurs ne sont disponibles qu’à des périodes précises dans l’année.</p>
                        <p class="mt-5">Depuis un demi-siècle, Fleuriste Bergeron a été témoin et collaboré à une multitude de moments heureux. Il est toujours très gratifiant et émouvant d’avoir à conseiller les enfants de ceux qui ont uni leur destiné il y a de nombreuses années maintenant. Il est toujours bien flatteur d’être présents de génération en génération…</p>
                        <p class="mt-5">Nous vous invitons à nous confier votre projet. Nous saurons faire de cette journée tant attendue, une journée de rêve dont vous vous souviendrez très longtemps.</p>
                    </v-col>
                </v-row>
            </div>
            
            <v-row class="mt-16" style="position:relative;">
                <div class="pinkRectangle"></div>
                <v-col cols="12" lg="8" sm="12" style="position:relative;"> <!--Lien : https://codepen.io/kasia123/pen/xxEmgmG-->
                    <p class="textDiapo">Note : Ces conceptions présentées ne sont pas disponibles en ligne. Nous vous suggérons de fixer un rendez-vous avec l’un de nos fleuristes chevronnés.</p>
                    <div class="Diapo">
                        <v-row class="diapoWedding">
                            <v-carousel class="v-WeddingConception-00" :show-arrows="false">
                                <template v-for="(item, index) in Items" >
                                    <v-carousel-item  v-if="(index + 1) % columns === 1 || columns === 1" :key="index" >
                                        <v-row class="flex-nowrap hw-full"> 
                                            <template v-for="(n,i) in columns" > 
                                                <template v-if="(+index + i) < Items.length" > 
                                                    <v-col :key="i">
                                                        <v-sheet v-if="(+index + i) < Items.length"  height="100%" width="100%" class="bg-transparent">
                                                            <v-row class="fill-height" align="center" justify="center">
                                                                <div class="imgSelector">
                                                                    <v-img @click="changePhoto(Items[+index + i].src)" cover height="1000px" width="1000px" :src="Items[+index + i].src"></v-img>
                                                                </div>
                                                            </v-row>
                                                        </v-sheet>
                                                    </v-col>
                                                </template>
                                            </template>
                                        </v-row>
                                    </v-carousel-item>
                                </template>
                            </v-carousel>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="d-none d-lg-flex">
                    <div class="largePhotoFixed"><v-img  id="principalImage" class="largePhoto" :src="selected.src" cover></v-img></div>
                </v-col>
            </v-row>
        </v-container>
        <Footer class="mt-10"/>
    </div>
</template>
<style>
    @media handheld, only screen and (max-width: 610px) {
        .v-carousel__controls {background: transparent; display: block; bottom: 10px; text-align: center;}
    }
    div.v-WeddingConception-00 .v-responsive__sizer {flex: 0 0 0px;}
    .v-carousel__controls {background: transparent;}
    .v-carousel__controls button {color: rgb(var(--primary));}
    .v-btn--selected {color: black !important;}
</style>
<style scoped>
    .h-5 {height:5px;}
    .bg-transparent {background:transparent;}
    .imgSelector {height: 375px; width:275px;}
    .hw-full {height:100%; width:100%;}
    .fixingTop {height: 150px;}
    .fixingSize {padding : 0px 100px 0px 100px !important;}
    .firstText {border-right: 1px solid rgb(var(--secondary)); font-size: 24px; text-align: justify; padding: 0px 100px !important; margin-top: 5% !important;}
    .secondText {font-size: 24px; text-align: justify; padding: 0px 100px !important; margin-top: 5% !important;}
    .largePhotoFixed { height: 800px;  width: 800px; float: right; bottom: 0;}
    .smallPhotoFixed {height:300px; width: 225px; margin-left: auto; margin-right: auto; display: block;}
    .smallPhotoDiv {height:240px; padding: 0px 10% 0px 15% !important;}
    .finalHeight {height: 200px;}
    .smallPhoto {height:100%;}
    .largePhoto {height:100%;}
    .centerDot {width: 100%; text-align: center;}
    .dot {height: 10px; width: 10px; background-color: #bbb; border-radius: 50%;display: inline-block; margin: 5px !important; }
    .Selected { background-color: rgb(var(--primary)) !important;}
    .v-responsive {flex: 0 0 0px !important;}
    .v-img--booting .v-responsive__sizer {transition: none; flex: 0 0 0px !important;}
    .pinkRectangle { height: 200px; width: 100%; position: absolute; bottom: 0; background-color: rgba(var(--secondary),0.5);}
    .textDiapo {width: 80%; font-family: Ephesis; font-size: xxx-large; text-align: center; margin-left: auto !important; margin-right: auto !important; color: rgb(var(--primary)); margin-top: 5% !important;}
    .Diapo {width:100%; position:absolute; bottom:0;}
    .topBanner {height: 500px; background-image: url('../../public/Resource/Banner/bannerMariage.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}
    .topBannerColor { height: 500px; width: 100%; background-color: rgba(var(--secondary),0.5);}
    @media handheld, only screen and (max-width: 610px) {

    }
    @media handheld, only screen and (max-width: 960px) {
        .firstText { padding: 0px !important;}
        .secondText { padding: 0px !important;}
        .fixingTop {height: 0px !important;}
    }
    @media handheld, only screen and (max-width: 1279px) {
        .firstText { border-right: 0px solid; border-bottom: none; padding: 0px 10px 0px 10px  !important;}
        .secondText {font-size: 24px; text-align: justify; padding: 0px 10px 0px 10px  !important; margin-top: 5% !important;}
        .textDiapo {width: 100%;  font-size: xxx-large; text-align: center; margin-left: auto !important; margin-right: auto !important; margin-top: 5% !important;}
        .diapoWedding {position: none;}
        .Diapo {width:100%; position:relative; bottom:0;}
    }
    @media handheld, only screen and (max-width: 1526px) {
        .smallPhotoDiv { height:240px; padding: 0px 5% 0px 5% !important; }
        .firstText { border-right: 0px solid; border-bottom: none; padding: 0px 10px 0px 10px  !important;}
        .secondText {font-size: 24px; text-align: justify; padding: 0px 10px 0px 10px  !important; margin-top: 5% !important;}
        .fixingSize {padding : 0px 20px 0px 20px !important;}
    }
    
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'

export default {
  name: 'Event',
  data: () => ({
    Items:[
        {src:'Resource/Diapo/Wedding/BouquetM.JPG'},
        {src:'Resource/Diapo/Wedding/BouquetN.jpg'},
        {src:'Resource/Diapo/Wedding/BouquetO.jpg'},
        {src:'Resource/Diapo/Wedding/BouquetF.jpg'},
        {src:'Resource/Diapo/Wedding/centreTable-Large.jpg'},
        {src:'Resource/Diapo/Wedding/Flower1-Large.jpg'},
        {src:'Resource/Diapo/Wedding/flower2-Large.jpg'},
        {src:'Resource/Diapo/Wedding/flower3-Large.jpg'},
        {src:'Resource/Diapo/Wedding/flower4-Large.jpg'},
        {src:'Resource/Diapo/Wedding/flower5-Large.jpg'},
        {src:'Resource/Diapo/Wedding/JoanieBourassa-Large.jpg'},
        {src:'Resource/Diapo/Wedding/mariage-marie-michelle-martin-2977-Large.jpg'},
        {src:'Resource/Diapo/Wedding/mariage-marie-michelle-martin-3149-Large.jpg'},
        {src:'Resource/Diapo/Wedding/mariage-marie-michelle-martin-3197-Large.jpg'},
        {src:'Resource/Diapo/Wedding/mariage-marie-michelle-martin-4179-Large.jpg'},
        {src:'Resource/Diapo/Wedding/MariageMichelleG-124-Large.jpg'},
        {src:'Resource/Diapo/Wedding/MariageMichelleG-382-Large.jpg'},
        {src:'Resource/Diapo/Wedding/mariee1-Large.jpg'},
        {src:'Resource/Diapo/Wedding/mariee2.jpg'},
    ],
    selected: {src:'Resource/Diapo/Wedding/centreTable-Large.jpg',},
    columns: 3,
  }),
  mounted() {  
    this.changeColums();
    window.addEventListener('resize', this.changeColums)  
  },  
  beforeUnmount() {
    window.removeEventListener('resize', this.changeColums)  
  },
  computed: {

  },
  methods:{
    changePhoto: function(src) {
        document.getElementById("principalImage").lastElementChild.src = src;
    },
    changeColums: function(){
        var w = window.document.documentElement.clientWidth;
        console.log(w);
        if (w < 700) {this.columns = 1;} 
        else if (w < 960) {this.columns = 2;}
        else{this.columns= 3;}
    }
  },
  components: {
    Header,
    Footer,
    Banner
},
}
</script>