import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import application from './../public/Resource/scripts/app.js'
import { directives } from './directives.js'

import * as filters from './filters.js'

import $event from './event.js'

loadFonts()

//createApp($App);
const app = createApp(App);
app.use(router)
  .use(vuetify)
  .use(application)

if(!$App.event){ $App.event = $event; }

// Enregistrer les directives globales
for (const directiveName in directives) {
  const directive = directives[directiveName];
  app.directive(directiveName, directive);
}

// Initialize the $filters object
app.config.globalProperties.$filters = {};

// Register filters
Object.keys(filters).forEach(key => {
  app.config.globalProperties.$filters[key] = filters[key];
});

app.mount('#app')

/*
createApp(App)
  .use(router)
  .use(vuetify)
  .use(application)
  .use(filters)
  .mount('#app')
  */


  