<template>
    <div>
        <Header/>
        <div class="fixingTop"></div>
        <Banner/>

        <h1 class="pageTitleHeight">Nos Conceptions</h1>
        <h2 class="soustitre">Voici quelques belles réalisations et suggestions.</h2>
        <!--Example Slick : https://gs-shop.github.io/vue-slick-carousel/#/example/simple -->
        <v-container>
            <div class="center mx-auto diapoModif">
              <div v-for="(item, index) in Items">
                <div class="shapeItemDiapo">
                  <div @click="imgDialog(item)" class="imgItemAdjust">
                    <v-img class="imgItem" :src="item.src"></v-img>
                  </div>              
                </div>
                <div class="rectangle"><h2>{{item.Title}}</h2></div>
              </div>
            </div>
        </v-container>
        <Footer class="mt-10"/>

      <v-dialog v-model="this.dialog.show" >
        <v-card>
          <v-card-text>
            <h2 class="mt-5 dialogBoxTitle">{{this.dialog.Title}}</h2>
            <v-img class="dialogBoxImg" :height="this.dialog.height" :width="this.dialog.width" :src="this.dialog.src"></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>
<style>
  .slick-dots li button { width:15px;height:15px;
    background-color:rgb(var(--secondary)) !important;
    border-radius:50% !important;
    font-size:0px !important;
    color: white;
    border:0 !important;
    opacity: 1 !important;
  }
  .slick-dots .slick-active button
  {
    background-color: rgb(var(--primary)) !important;
    opacity: 1 !important;
  }
  .slick-center { opacity: 1 !important;}
  .slick-center div {box-shadow: 0px 0px 35px #000000;}
  .slick-center .rectangle {box-shadow: none; display: block;}
  .slick-dots li button::before {
    color: transparent !important;   
  }
  .rectangle { display: none; max-width: 200px; margin-left: auto !important; margin-right: auto !important; background-color: rgb(var(--secondary)); color: #fff; text-align: center; box-shadow: none; transform: skew(30deg,0deg);}
  .rectangle h2 {padding: 5px 0px 5px 0px !important; transform: skew(-30deg,0deg); margin-top: -70px !important; color: rgb(var(--primary)); text-shadow: 1px 1px 3px #FFFFFF }
</style>
<style scoped>
    .dialogBoxTitle {text-align: center; font-family: Ephesis; color: rgb(var(--primary)); font-size: 50px;}
    .dialogBoxImg {margin: 20px !important;}
    .diapoModif {height: 450px !important; display:block;}
    .imgItem {height: 475px !important; width: 475px !important;}
    .imgItemAdjust {transform: rotate(-45deg); height: 475px !important; width: 475px !important; margin-top: -50% !important; margin-left: -50% !important;}
    .fixingTop {height: 150px;}
    .shapeItemDiapo {display: block; height: 250px !important; width: 250px !important; background-color: black; margin: 20% auto !important; color:white; transform: rotate(45deg); overflow: hidden; border: 10px solid rgb(var(--primary));}
    .slick-slide {height: 450px !important; opacity: 0.25;}
    .v-container{max-width: none;}

    @media handheld, only screen and (max-width: 600px) {
      .pageTitleHeight {height: auto;}
    }
    @media handheld, only screen and (max-width: 960px) {.fixingTop {height: 0px !important;}}
    @media handheld, only screen and (max-width: 1264px) {
      .v-container {max-width: 1800px !important;}
    }
    @media handheld, only screen and (max-width: 1920px) {
      .v-container {max-width: 1920px !important;}
    }
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'

export default {
  name: 'Event',
  data: () => ({
    Items:[
        {src:'Resource/Diapo/Standard/Bac1.jpg',Title:'Élégance',height:"500px", width:"666px"},
        {src:'Resource/Diapo/Standard/bac2.jpg',Title:'Originalité',height:"666px", width:"500px"},
        {src:'Resource/Diapo/Standard/berg-cat063.jpg',Title:'Harmonie',height:"500px", width:"666px"},
        {src:'Resource/Diapo/Standard/berg-cat066.jpg',Title:'Esprit Zen',height:"500px", width:"666px"},
        {src:'Resource/Diapo/Standard/couronne.jpg',Title:'Moment festif',height:"666px", width:"500px"},
        {src:'Resource/Diapo/Standard/fleurPancarte.jpg',Title:'Célébration',height:"500px", width:"666px"},
        {src:'Resource/Diapo/Standard/panierBoisFleur.jpg',Title:'Au naturel',height:"500px", width:"666px"},
        {src:'Resource/Diapo/Standard/panierDeFruits.jpg',Title:'Appétissant',height:"500px", width:"500px"},
        {src:'Resource/Diapo/Standard/panierPlastiqueFleur.jpg',Title:'Simplicité',height:"500px", width:"666px"},
        {src:'Resource/Diapo/Standard/panierRocheFleur.jpg',Title:'Ravissement',height:"631px", width:"500px"},
        {src:'Resource/Diapo/Standard/troisFleurMetal.jpg',Title:'Fantaisie',height:"643px", width:"500px"},
    ],
    model: null,
    dialog: {
      show: false,
      src: '../../public/Resource/Diapo/Standard/Bac1.jpg',
      Title: 'Title of picture',
      height: '500px',
      width:'500px'
    },
  }),
  computed: {
  },
  mounted: async function () {
    this.$nextTick(function () {
      DiapoConceptionStandard();
    })
    
  },
  methods:{
    imgDialog: function(item) {
      this.dialog.src = item.src;
      this.dialog.Title = item.Title;
      this.dialog.height = item.height;
      this.dialog.width = item.width;
      this.dialog.show = true;
    }
  },
  components: {
    Header,
    Footer,
    Banner,

},
}
</script>