<template>
    <div > <!--class="md-content"-->
        <!-- RL not remove next div with v-if (use for binding)... -->
        <div v-if="ready"></div>
        <div v-if="current" class=""> <!--class="md-content"-->
            <v-row class="" >
                <v-col cols="12">
                    <v-text-field name="txtNumber" label="xxxxxxxxxx" single-line @input="formatPhoneNumber" v-model="current.number" @change="updated"  :readonly="isReadOnly" :required="isRequired" />
                </v-col>
            </v-row>
            <!--
            <v-row>
                <v-text-field :class="$v.current.extension|validations" name="txtExtension" label="Extension" v-model.number="current.extension" type="text" />
            </v-row>-->
        </div>
        <!-- <field-desc v-if="includeDescription != false" title="Description" v-bind:item.sync="current" property="description" :readonly="false" />-->
    </div>
</template>
<style scoped>
</style>
<script>
    //import { required, requiredIf, requiredUnless, between, numeric } from 'vuelidate/lib/validators'
    export default {
        name: 'phone',
        props: {
            item: Object,
            includeDescription: Boolean,
            readonly: Boolean,
            required: Boolean
        },
        data: () => ({
            types: [],
            current: {},
        }),
        computed: {
            ready: function () {
                let val = false;
                if (this.types && this.types.length > 0) {
                    if (this.item) {
                        this.current = this.item;
                        val = true;
                    }
                }
                return val;
            },
            isReadOnly: function() {
                return this.readonly;
            },
            isRequired: function() {
                return this.required;
            }
        },
        mounted: async function () {
            //await this.typesRead();

            this.current = this.item;

            // Default value for typeId
            /*
            if (this.current && this.current.typeId == 0 && this.types.length > 0) {
                this.current.typeId = this.types[0].id;
            }*/
        },
        methods: {
            typesRead: async function () {
                /*
                let data = await this.ListAllPhoneType();
                if (data) {
                    this.types = data;
                }*/

            },
            typeSelected: function (typeId) {
                /*
                if (typeId != 0) {
                    let type = this.types.find(e => e.id == typeId);
                    if (type) {
                        this.current.type = type;
                    }
                }*/
            },
            updated: function(){this.$emit("update:item", this.current);},
            formatPhoneNumber() {
                    // Supprimer les tirets et autres caractères non numériques
                this.current.number = this.current.number.replace(/[^0-9]/g, '');
                let number  = 0; 
                let validNumber = true;

                try{number = Number(this.current.number); } catch { validNumber= false;}
                if(validNumber) { this.current.number = number; }
                
                    // Limiter la longueur du numéro à 9 chiffres
                if (this.current.number.length > 11) {            
                    this.current.number = this.current.number.substring(0, 11);
                } else if(this.current.number.length == 0) { this.current.number = 0; }
            },
        },
    }
</script>
