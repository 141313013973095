<template>
    <div :class="{ motherDayBackground: motherDay, birthDayBackground: birthDay, easterDayBackground: easterDay, weddingDayBackground: weddingDay, ChristmasDayBackground: ChristmasDay, AdministratorWomanDayBackground: administratorWomanDay, sympathyDayBackground: sympathyDay, valentinDayBackground: valentinDay}">
        <Header/>
        <div class="fixingTop"></div>
        <div class="topBanner">
            <div class="topBannerColor"></div>
        </div>

        <h1 class="pageTitleHeight">Événements</h1>
        <h2 class="soustitre">Les occasions pour offrir des fleurs aux gens que l’on apprécie.</h2>

        <v-container fluid>
            <div class="grid-pad">
                <v-row class="mt-5 grid-pad-mobile">
                    <v-col  cols="12" md="6" lg="4">
                        <router-link to="/Boutique/8" target="_blank"><div class="eventBGPhoto"  @mouseover="sympathyDay = true" @mouseleave="sympathyDay = false" ><h2 class="eventText">Sympathie</h2></div></router-link>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <router-link to="/Boutique/10" target="_blank"><div class="eventBGPhoto" @mouseover="birthDay = true" @mouseleave="birthDay = false"><h2 class="eventText">Naissance</h2></div></router-link>    
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <router-link to="/Boutique/9" target="_blank"><div class="eventBGPhoto" @mouseover="weddingDay = true" @mouseleave="weddingDay = false"><h2 class="eventText">Mariage</h2></div> </router-link>   
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <router-link to="/Boutique/12" target="_blank"><div class="eventBGPhoto" @mouseover="valentinDay = true" @mouseleave="valentinDay = false"><h2 class="eventText">St-Valentin</h2></div></router-link>              
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <router-link to="/Boutique/13" target="_blank"><div class="eventBGPhoto" @mouseover="easterDay = true" @mouseleave="easterDay = false"><h2 class="eventText">Pâques</h2></div></router-link>    
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <router-link to="/Boutique/14" target="_blank"><div class="eventBGPhoto" @mouseover="administratorWomanDay = true" @mouseleave="administratorWomanDay = false"><h2 class="eventText">Semaine adj admin</h2></div></router-link>    
                    </v-col>
                    <v-col cols="0" md="0" lg="2"></v-col>
                    <v-col cols="12" md="6" lg="4">
                        <router-link to="/Boutique/15" target="_blank"><div class="eventBGPhoto" @mouseover="motherDay = true" @mouseleave="motherDay = false"><h2 class="eventText">Fête des mères</h2></div></router-link>              
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <router-link to="/Boutique/11" target="_blank"><div class="eventBGPhoto" @mouseover="ChristmasDay = true" @mouseleave="ChristmasDay = false"><h2 class="eventText">Noël</h2></div></router-link>    
                    </v-col>
                    <v-col cols="0" md="0" lg="4"></v-col>
                </v-row>
            </div>
        </v-container>
        <Footer/>
    </div>
</template>
<style scoped>
    .fixingTop {height: 150px;}
    .eventBGPhoto {position:relative; background-image: url('../../public/Resource/EventRectangle.png'); background-position: center center; background-size:contain; height: 300px; width: 100%; margin: 10px !important;}
    .eventText { font-family: Ephesis; color: #000; font-size: 64px; position:absolute; top:50%; transform: translate(0%, -100%); line-height: 64px; width:100%; text-align: center;}
    .eventText:hover {color: rgb(var(--secondary)) !important;}
    .motherDayBackground {background-image: url('../../public/Resource/event/fetedesmeres.jpg'); background-position: bottom center; background-repeat: no-repeat; background-size: contain;}
    .birthDayBackground {background-image: url('../../public/Resource/event/baby.jpg'); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .easterDayBackground {background-image: url('../../public/Resource/event/paque.jpg'); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .weddingDayBackground {background-image: url('../../public/Resource/event/mariage.jpg'); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .ChristmasDayBackground {background-image: url('../../public/Resource/event/gift.jpg'); background-position: bottom center; background-repeat: no-repeat; background-size: contain;}
    .AdministratorWomanDayBackground {background-image: url('../../public/Resource/event/semAdjAdm.jpg'); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .sympathyDayBackground {background-image: url('../../public/Resource/event/sympathie.jpg'); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .valentinDayBackground {background-image: url('../../public/Resource/event/StValentin.jpg'); background-position:  center center; background-repeat: no-repeat; background-size: contain;}
    .topBanner {height: 500px; background-image: url('../../public/Resource/Banner/bannerEvent.jpg'); background-position: center; background-repeat: no-repeat; background-size: cover;}
    .topBannerColor { height: 500px; width: 100%; background-color: rgba(var(--secondary),0.5);}
    
    @media handheld, only screen and (max-width: 600px) {
        .eventText { font-size: 54px; }
    }
    @media handheld, only screen and (max-width: 960px) {
        .grid-pad-mobile {padding: 10px !important;}
        .eventBGPhoto {margin: 0px !important;}
        .motherDayBackground {background-image: none !important;}
        .birthDayBackground {background-image: none !important;}
        .easterDayBackground {background-image: none !important;}
        .weddingDayBackground {background-image: none !important;}
        .ChristmasDayBackground {background-image: none !important;}
        .AdministratorWomanDayBackground {background-image: none !important;}
        .sympathyDayBackground {background-image: none !important;}
        .valentinDayBackground {background-image: none !important;}
        .fixingTop {height: 0px !important;}
    }
    @media handheld, only screen and (max-width: 1264px) {
    }
    
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'

export default {
  name: 'Event',
  data: () => ({
    motherDay: false,
    birthDay: false,
    easterDay: false,
    weddingDay: false,
    ChristmasDay: false,
    administratorWomanDay: false,
    sympathyDay: false,
    valentinDay: false,
  }),
  mounted: async function () {

  },
  methods:{

  },
  components: {
    Header,
    Footer,
    Banner
},
}
</script>