
export const $ = (value, nullValue ) => {
  if(value != null) { 
    if (value != 0) {
      value = value.toFixed(2);          
      return value;
    } else {
      return '0.00';
    }
  } else if( nullValue != null) {
    if (nullValue != 0) {
      nullValue = '[' + nullValue.toFixed(2) + ']';
      return nullValue;
    } else {
      return '[0.00]';
    }
  }
}
export const demo = (value, nullValue ) => {
  if(value != null) { 
    if (value != 0) {
      value = value.toFixed(2);          
      return value;
    } else {
      return 'Démonstrateur';
    }
  } else if( nullValue != null) {
    if (nullValue != 0) {
      nullValue = '[' + nullValue.toFixed(2) + ']';
      return nullValue;
    } else {
      return 'Démonstrateur';
    }
  }
}

export const YYYYMMDD = (value) => {
  if (value && value != '0001-01-01T00:00:00+00:00' && value != '0001-01-01T00:00:00Z' ) {
    var d = new Date(value),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  } else {
    return '';
  }
}

export const YYYYMMDDhhmm = (value) => {
  if (value && value != '0001-01-01T00:00:00+00:00' && value != '0001-01-01T00:00:00Z') {
    var d = new Date(value).toLocaleDaylight(),
        min = '' + d.getMinutes(),
        hour = '' + d.getHours(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    if (min.length < 2)
      min = '0' + min;

    var time = [hour, min].join(':');
    var other = [year, month, day].join('-');
    return other + ' ' + time;
  } else {
    return '';
  }
}
export const phone = (value) => {
  if (value) {
    var phone = value.toString();

    var c = phone.substring(6, 10);
    var b = phone.substring(3, 6);
    var a = phone.substring(0, 3);

    return a + '-' + b + '-' + c;
  }
}

export const registerAll = (app) => {
  app.config.globalProperties.$filters = {
    $,
    YYYYMMDD,
    YYYYMMDDhhmm
  }
}
