<template>
    <div class="diapo">
        <div class="heightDiapo"></div>
        <v-carousel class="diapoHeight" cycle :interval="8000" :show-arrows="false">
            <v-carousel-item cover>
                <v-row class="FirstDiapo">
                    <v-col cols="12" md="4"></v-col>
                    <v-col cols="12" md="8">
                        <p class="titleFirstDiapo">Un demi-siècle à toujours mieux vous servir</p>
                        <p class="subTitleFirstDiapo">Parcourez notre site en ligne</p>
                        <div class="fullwidth mt-10"><hr class="borderFirstDiapo"/></div>
                        <v-btn to="/Boutique" target="_blank" class="mt-10 btnFirstDiapo" >Boutique en ligne</v-btn>
                    </v-col>
                </v-row>
            </v-carousel-item>
            <v-carousel-item>
                <v-row class="SecondDiapo">
                    <v-col cols="12" md="6"></v-col>
                    <v-col cols="12" md="6" class="mobileSecondPadding">
                        <p class="titleSecondDiapo">Un mariage digne de votre personnalité</p>
                        <p class="subTitleSecondDiapo">Prenez rendez-vous</p>
                        <v-btn to="/weddingconception" class="mt-10 btnSecondDiapo" >Inspirez-vous</v-btn>
                    </v-col>

                </v-row>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>
<style>
    div.diapo .v-responsive__sizer {flex: 0 0 0px;}
</style>
<style scoped>
    .FirstDiapo { height: 600px; background-image: url('../../public/Resource/Diapo/Diapo-1.jpg'); background-position: center center; background-size: contain; background-repeat: no-repeat;}
    .titleFirstDiapo { width: 100%; text-align: center; margin-top: 10% !important; color: rgb(var(--primary)); font-size: 34px; font-weight: 200;}
    .subTitleFirstDiapo {width: 100%; text-align: center; margin-top: 5px; color: rgb(var(--secondary)); font-size: 24px; font-weight: 200;}
    .borderFirstDiapo {width: 30%;  display: block; margin-right: auto !important; margin-left: auto !important; color: rgb(var(--secondary));}
    .btnFirstDiapo{padding: 10px !important; display: block; width: 175px; margin-right: auto !important; margin-left: auto !important; background-color: rgb(var(--primary),0.5);}
    .btnFirstDiapo:hover{background-color: rgb(var(--primary),0.7);}

    .SecondDiapo { height: 600px; background-image: url('../../public/Resource/Diapo/Diapo-2.jpg'); background-position: center center; background-size: cover; background-repeat: no-repeat;}
    .titleSecondDiapo { width: 100%; margin-top: 10% !important; color: rgb(var(--primary)); font-size: 34px;  text-shadow: 1px 1px 2px #fff;}
    .subTitleSecondDiapo {width: 100%; margin-top: 5px; color: rgb(var(--secondary)); font-size: 24px; text-shadow: 1px 1px 2px #fff;}
    .btnSecondDiapo{padding: 25px !important; background-color: rgb(var(--primary),0.5) !important; color: white;}
    .btnSecondDiapo:hover{background-color: rgb(var(--primary)) !important;}

    .fullwidth{width: 100%;}
    .heightDiapo {height: 150px;}
    .diapo {margin-bottom: 5px;}
    .v-img__img--contain{ object-fit: cover !important; }

    @media handheld, only screen and (max-width: 600px) {.heightDiapo {height: 30px;}}
    @media handheld, only screen and (max-width: 960px) {
        .FirstDiapo { background-image: url('../../public/Resource/Diapo/Diapo-Mobile.jpg'); background-position: center center; background-size: cover; background-repeat: no-repeat;}
        .titleFirstDiapo { width: 100%; text-align: center; margin-top: 0% !important; font-size: 24px; font-weight: 400;}
        .SecondDiapo { background-image: url('../../public/Resource/Diapo/Diapo-2.jpg'); background-position: center center; background-size: cover; background-repeat: no-repeat;}
        .titleSecondDiapo { width: 100%; margin-top: 10% !important; color: rgb(var(--primary)); font-size: 34px; font-weight: 200; text-shadow: 1px 1px 3px #fff;}
        .heightDiapo {height: 10px;}
    }
    @media handheld, only screen and (max-width: 1400px) {
        .titleFirstDiapo { color: rgb(var(--primary)); font-size: 24px !important;}
        .subTitleFirstDiapo {width: 100%; font-size: 24px !important;}
        .borderFirstDiapo {width: 40%;  display: block; margin-right: auto !important; margin-left: auto !important; color: rgb(var(--secondary));}
        .btnFirstDiapo{padding: 10px !important; margin-right: auto !important; margin-left: auto !important; background-color: rgb(var(--primary),0.5);}

        .titleSecondDiapo { width: 100%; margin-top: 5% !important; color: rgb(var(--primary)); font-size: 24px; font-weight: 200; text-shadow: 1px 1px 3px #fff;}
        .mobileSecondPadding {padding: 0px 20px !important;}
    }  
</style>
<script>
  export default {
    name: 'Diapo',
    data: () => ({
    }),
    
    mounted() {

    },
    methods:{
        calculateHeight() {
            var w = window.innerWidth;
            var h = '600'
            if(w <= 600){h = 200}
            else if(w > 600 && w <= 960){h = 500}
            else {w = 600}
            return h;
        }
    },
    component: {

    }
    
  }
</script>