<template>
    <div class="flowerBackground">
        <Header/>
        <div class="fixingTop"></div>
        <div class="topBanner">
            <div class="topBannerColor"></div>
        </div>
        <h1>Notre histoire</h1>
        <v-container fluid class="AlignTopPhoto">
            <v-row>
                <v-col cols="12" lg="6">
                    <p class="firstText">Octobre 1972 annonce le commencement d’une belle aventure soit la fondation d’un nouveau fleuriste à Drummondville situé au 506 rue Lindsay. Monsieur Gérald Bergeron,  dont les premières lettres ont orné le logo de l’entreprise pendant des décennies, en est l’inspirateur.</p>
                </v-col>
                <v-col cols="12" lg="6" class="d-none d-md-flex">
                    <v-img class="IMG2" src="../../public/Resource/FiftyYears/storyIMG2.jpg"></v-img>
                    <v-img class="IMG1" src="../../public/Resource/FiftyYears/storyIMG1.jpg"></v-img>
                    <v-img class="IMG4" src="../../public/Resource/FiftyYears/storyIMG4.jpg"></v-img>
                </v-col>
            </v-row>
            <v-row class="d-flex d-md-none">
                <v-img class="MobileIMG1" src="../../public/Resource/FiftyYears/storyIMG1.jpg"></v-img>
                <v-img class="MobileIMG2" src="../../public/Resource/FiftyYears/storyIMG2.jpg"></v-img>
                <v-img class="MobileIMG4" src="../../public/Resource/FiftyYears/storyIMG4.jpg"></v-img>
            </v-row>
            <v-row>
                <p class="text2 f-secondary">Un an après l’ouverture et dans la jeune quarantaine monsieur Bergeron est emporté par la maladie. Il laissera à son épouse, madame Sylvianne Ouellette Bergeron, la pleine gestion de l’entreprise pendant la moitié de son existence. Un travail colossal l’attendait. Mère de trois enfants, elle a dû batailler fort pour se frayer un chemin dans un monde d’affaires réservé à cette époque à la gent masculine. </p>
            </v-row>
            <v-row>
                <p class="text3">Son esprit d’initiative, sa ténacité et son ardeur au travail auront eu raison des préjugés et permis au commerce de «fleurir» au fil des ans. Deux agrandissements viendront confirmer le succès de l’entreprise à cet endroit. Bien sûr, elle a été épaulée par de dévoués employés dont son fils Marc. Reconnu pour ses études poussées à l’amélioration des techniques du métier, à sa participation à de nombreux ateliers et à la communication de ses connaissances comme professeur, Marc Bergeron s’est aussi démarqué à l’international pendant plusieurs années en glanant diverses mentions lors de compétitions de «landart». Notons également l’apport inestimable d’une fleuriste chevronnée en la personne de Denise Lemonde. Cette dernière s’est aussi permise des escales en Europe afin d’enrichir ses compétences, les appliquer et les transmettre à son tour.</p>
            </v-row>
            <v-row class="d-none d-md-flex sectionTwoPhoto">
                <v-col cols="12" md="6" class="paddingMiddleIMG" >
                    <v-img class="IMG5" src="../../public/Resource/FiftyYears/storyIMG3.jpg"></v-img>
                </v-col>
                <v-col cols="12" md="6" class="paddingMiddleIMG">
                    <v-img class="IMG6" src="../../public/Resource/FiftyYears/storyIMG6.jpg"></v-img>
                </v-col>
            </v-row>
            <v-row class="d-flex d-md-none">
                <v-img class="IMG5 mt-20" style="" src="../../public/Resource/FiftyYears/storyIMG3.jpg"></v-img>
                <v-img class="IMG6 mt-20" style="" src="../../public/Resource/FiftyYears/storyIMG6.jpg"></v-img>
            </v-row>
            <hr>
            <v-row >    
                <p class="text4">Oeuvrant au service de madame Bergeron depuis son adolescence, Camil St-Onge compte parmi ce trio tout rêvé ayant permis au commerce de gagner ces lettres de noblesse et maintenir sa crédibilité et ce, malgré certaines périodes de crises financières. Employé loyal, fidèle et d’une disponibilité incomparable au dire de madame Bergeron, Camil est vite devenu un indispensable. 1997 marque l’arrivée en scène de cet homme à titre, cette fois, de nouvel acquéreur. Son épouse, Josée Nadeau, l’a toujours appuyé dans ses choix professionnels, même les plus fous.  Avec un chiffre d’affaires en constante évolution et un emplacement du commerce ne permettant aucune expansion, le choix s’impose de relocaliser les opérations. Ce souhait se réalisera en 2007 à l’emplacement actuel couvrant 5000 pieds carrés au 1380 rue Hébert.</p>
            </v-row>
            <v-row class="d-none d-md-flex AlignTopPhoto">
                <v-col cols="12">
                    <v-img class="IMG7" src="../../public/Resource/FiftyYears/storyIMG7.jpg"></v-img>
                <v-col cols="12">
                </v-col>
                    <v-img class="IMG9" src="../../public/Resource/FiftyYears/storyIMG5.jpg"></v-img>
                </v-col>
            </v-row>
            <v-row class="d-flex d-md-none">
                <v-img class="MobileIMG7 " src="../../public/Resource/FiftyYears/storyIMG7.jpg"></v-img>
                <v-img class="MobileIMG9 " src="../../public/Resource/FiftyYears/storyIMG5.jpg"></v-img>
            </v-row>
            <hr id="secondHr">
            <v-row>
                <p class="text5 f-secondary">Cette décision mûrement réfléchie permettra d’offrir, outre les fleurs et plantes, une variété de produits diversifiés. Nous travaillons de façon énergique à demeurer à l’écoute de notre clientèle afin d’offrir non seulement un choix accentué de produits de qualité supérieure mais, de nouvelles présentations florales épousant les nouvelles tendances.</p>
                <p class="text5 f-primary">À titre de propriétaire, je dois avouer que je ne porte pas seul le succès de Fleuriste Bergeron. De fait, une dizaine d’employé(e)s à l’écoute de tous besoins me soutiennent dans ce défi constant.</p>
            </v-row>
        </v-container>
        <Footer class="mt-10"/>
    </div>
</template>
<style scoped>
    .finalHeight {height: 50px;}
    hr {display:block; width: 50%; border: 1px solid rgb(var(--secondary)); margin: 100px auto !important;}
    hr#secondHr{display:block; width: 50%; border: 1px solid rgb(var(--secondary)); margin: 200px auto 50px auto !important;}
    .fixingTop {height: 150px;}
    .AlignTopPhoto {margin-top: 5% !important;}
    .IMG1{height: 650px; width: 910px; float: right;}
    .IMG2{position: absolute; z-index: 100; height: 386px; width: 257px; margin-top: -10% !important;}
    .IMG3{position: absolute; z-index: 100; height: 325px; width: 425px;  margin-top:400px !important;}
    .IMG4{position: absolute; z-index: 100; height: 461px; width: 300px; right: 0%; margin-top:400px !important;}
    .firstText { border-right: 1px solid rgb(var(--secondary)); font-size: 24px; text-align: justify; padding: 0px 100px !important; margin-top: 30% !important;}
    .flowerBackground {background-image: url('../../public/Resource/FiftyYears/flowerBackground.png'); background-position-x: 0; background-position-y: 20%; background-repeat: no-repeat; background-size: 400px ;}
    .text2 { font-size: 24px; text-align: center; padding: 0px 13% 0px 13% !important; margin-top: 10% !important;}
    .text3 { font-size: 24px; text-align: justify; padding: 0px 100px !important; margin-top: 5% !important;}
    .text4 { font-size: 24px; text-align: justify; padding: 0px 100px !important; margin-top: 0% !important;}
    .text5 { font-size: 24px; text-align: center; padding: 0px 20px !important; margin-top: 2% !important;}
    .IMG5{float:right; height: 308px; width: 496px;}
    .IMG6{height: 308px; width: 496px;}
    .sectionTwoPhoto {margin-top: 5% !important;}
    .paddingMiddleIMG{padding: 0px 50px !important;}
    .IMG7{height: 556px; width: 826.91px; margin-left: auto !important; margin-right: auto !important;}
    .IMG8{position: absolute; z-index: 100; height: 323px; width: 243px; left: 69%; margin-top: -29% !important;}
    .IMG9{position: absolute; z-index: 100; height: 307px; width: 427px; right: 66%; margin-top:-10% !important;}
    .text5 { font-size: 24px; text-align: center; padding: 0px 13% 0px 13% !important; margin-top: 2% !important;}
    .topBanner {height: 500px; background-image: url('../../public/Resource/Banner/bannerStory.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}
    .topBannerColor { height: 500px; width: 100%; background-color: rgba(var(--secondary),0.5);}
    .mt-20 {margin-top: 20px !important;}
    @media handheld, only screen and (max-width: 600px) {       
        hr#secondHr{display:block; width: 50%; border: 1px solid rgb(var(--secondary)); margin: 50px auto 50px auto !important;}
        
    }
    @media handheld, only screen and (max-width: 960px) {
        .finalHeight {height: 450px;}
        .AlignTopPhoto {margin-top: 0% !important;}
        .MobileIMG1{display: block; margin-left: auto; margin-right: auto; margin-top: 20px !important; height: 300px; width: 100%; }
        .MobileIMG2{display: block; margin-left: auto; margin-right: auto; margin-top: 20px !important; height: 386px; width: 100%; }
        .MobileIMG4{display: block; margin-left: auto; margin-right: auto; margin-top: 20px !important; height: 350px; width: 100%; }
        .MobileIMG7{height: 356px; width: 826.91px; margin-top: 20px !important; }
        .MobileIMG8{height: 323px; width: 243px; margin-top: 20px !important;}
        .MobileIMG9{height: 307px; width: 427px; margin-top: 20px !important;}
        .firstText { border-right: none; font-size: 24px; text-align: justify; padding: 0px 20px !important; margin-top: 0% !important; }
        .text2 { font-size: 24px; text-align: justify; padding: 0px 20px !important; margin-top: 10% !important;}
        .text3 { font-size: 24px; text-align: justify; padding: 0px 20px !important; margin-top: 5% !important;}
        .text4 { font-size: 24px; text-align: justify; padding: 0px 20px !important; margin-top: 0% !important;}
        .text5 { font-size: 24px; text-align: justify; padding: 0px 20px !important; margin-top: 2% !important;}
        .fixingTop {height: 0px !important;}
    }
    @media handheld, only screen and (max-width: 1264px) {
        .firstText { border-right: none; margin-top: 0% !important; margin-bottom: 5% !important;}
        .text2 { margin-top: 15% !important;}
    }
    
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'

export default {
  name: 'Event',
  data: () => ({

  }),
  mounted: async function () {

  },
  methods:{

  },
  components: {
    Header,
    Footer,
    Banner
},
}
</script>