<template>
  <div class="footer v-tierce">
    <div class="flower">
        <v-row class="grid-pad mb-10">
          <v-col cols="0" md="0" lg="4">
          </v-col>
          <v-col cols="6" md="3" lg="2">
              <h3 class="f-primary">HEURES D'OUVERTURE</h3>
              <p class="mt-5">Lundi à mercredi: <span class="f-secondary">8h à 17h</span></p>
              <p>Jeudi et vendredi: <span class="f-secondary">8h à 18h</span></p>
              <p>Samedi: <span class="f-secondary">8h30 à 16h30</span></p>
              <p>Dimanche: <span class="f-secondary">9h à 13h</span></p>
          </v-col>
          <v-col cols="6" md="3" lg="2">
            <div class="mobilePLeft">
              <h3 class="f-primary">ACHETEZ EN LIGNE</h3>
              <router-link to="/event"><a class="mt-5">Événement</a></router-link>
              <router-link to="/story"><a>Notre histoire</a></router-link>
              <router-link to="/conception"><a>Nos conceptions</a></router-link>
              <router-link to="/condition"><a>Termes légaux et conditions</a></router-link>
            </div>
            </v-col>
          <v-col cols="6" md="3" lg="2">
            <div class="mobilePTop">
              <h3 class="f-primary">ADRESSE <a href="https://www.google.com/maps/place/Bergeron+G+Fleuriste/@45.8856383,-72.5014347,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc81162f8e96ab5:0x236075686fe2350b!8m2!3d45.8856346!4d-72.499246" target="_blank" style="display:inline;"><v-icon icon="mdi-google-maps"></v-icon></a></h3>
              <p class="mt-5">1380, rue Hébert</p>
              <p>Drummondville</p>
              <p>(Québec)</p>
              <p>J2C 1Z9</p>
            </div>
          </v-col>
          <v-col cols="6" md="3" lg="2">
            <div class="mobilePLeft mobilePTop">
              <h3 class="f-primary" style="visibility: hidden;">espacement</h3> <!--Impossible de faire une classe fonctionnelle-->
              <p class="mt-5"><span class="f-secondary">Tél.</span>: 819 477-4700</p>
              <p><span class="f-secondary">Téléc.</span>: 819 474-3857</p>
              <p><span class="f-secondary">Sans frais</span>: 1 877 477-4709</p>
            </div>
          </v-col>
        </v-row>
        <v-row class="v-primary-light-5 purpleBanner grid-pad">
          <v-col  cols="12" md="8">
            <p class="f-tierce copyrightText">©2024.07.16 - TOUS DROITS RÉSERVÉS | FLEURISTE BERGERON</p>
            <div class="cookieLink "><p @click="openCookie()" class="txt-r">Mes témoins</p></div>
            <div class="cookieLink pl-5"><p @click="navigateToPolicy()" class="txt-r">Politique de confidentialité</p></div>
          </v-col>
          <v-col  cols="12" md="4">
            <div class="right mt-3">
              <v-img src="../../public/Resource/footerlogo.png" class="logoFooter"></v-img>
              <p class="f-tierce ownerText">Camil St-Onge, propriétaire</p>
            </div>
          </v-col>
        </v-row>
    </div>
  </div>
</template>

<style scoped>
  .footer{position: relative; bottom: 0px; width: 100% !important; overflow: hidden; padding-top:30px !important;}
  .flower{background-image: url("../../public/Resource/orchidee-travailler.png"); background-size: 486px; background-repeat: no-repeat; background-position-x: -100px;}
  .purpleBanner {height: 100px; width: 100% !important;}
  a { display:block; text-decoration: none; color: black; }
  a:hover {color: rgb(var(--secondary)) !important;}
  .right {float: right; }
  .logoFooter {height: 51px; width: 251px; }
  .ownerText{text-align: right; font-size: 14px;}
  .copyrightText{margin-top: 45px !important;}
  .cookieLink { color: rgb(var(--tierce)) ; text-decoration: none ; cursor: pointer; display: inline-block;}
  .cookieLink:hover { text-decoration: underline ; color: rgb(var(--secondary)); }
  @media handheld, only screen and (max-width: 600px) {
    .mobilePLeft {padding-left:25px !important;}
    .mobilePTop {padding-top:20px !important;}
    .footer{height: 490px !important;}
    .ownerText{display: none;}
    
  }
  @media handheld, only screen and (max-width: 960px) {
    .copyrightText{display: none;}
    .footer{height: 440px;}
    .flower{background-image: none;}
  }
  @media handheld, only screen and (max-width: 1264px) {
  }

</style>
<script>
  export default {
    name: 'Footer',

    data: () => ({
    
    }),
    methods:{
      navigateToPolicy: function(){
        this.$router.push('/PolitiqueDeConfidentialiter');
      },
      openCookie: function(){
        $App.event.$emit('openCookie');
      }
    },
    component: {

    }
  }
</script>
