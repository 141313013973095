<template>
    <div cols="12" md="3" class="" style="">
        <div  v-if="ready" class=" ">
            <div class="summaryBG summary">
                <div class="grid-pad">
                    <h2 class="f-tierce">Résumé</h2>
                    <!--<p class="backText f-nobold" @click="this.backStep()">Retour</p>-->
                    <div v-if="this.current">
                        <v-row class="mt-5">
                            <v-col cols="8"  class="priceTitle">Sous-Total</v-col>
                            <v-col cols="4"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.subTotal) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"  class="priceTitle">
                                <v-col cols="12">Livraison </v-col>
                            </v-col>
                            <v-col cols="6"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.shipping) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                            <v-col cols="12" v-if="shippingRestrictiondate" class="detailedText">** La date minimale pour la livraison est {{ shippingRestrictiondate }} **</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"  class="priceTitle">TPS</v-col>
                            <v-col cols="6"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.gst) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"  class="priceTitle">TVQ</v-col>
                            <v-col cols="6"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.pst) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <hr class="mt-5 f-tierce" style="width:75%; display: block; margin-left: auto !important; margin-right: auto !important;">
                        <v-row class="mt-5">
                            <v-col cols="6"  class="priceTitle">Total</v-col>
                            <v-col cols="6"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.total) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                    </div>
                    <v-btn v-if="current && current.items.length == 0 && dirty == false"  class="mt-5 orderBtn">
                        <div @click="navigateTo">        
                            <h2  class="orderBtnTitle">Boutique</h2>
                        </div>
                    </v-btn>
                    <v-btn v-if="current && dirty == true"  class="mt-5 orderBtn">
                        <div @click="reEvaluate">   
                            <h2  class="orderBtnTitle">Rafraichir</h2>
                        </div>
                    </v-btn>
                    <v-btn v-if="current && Step != 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class="mt-5 orderBtn" :readonly="loading == true">
                        <div @click="NextStep">
                            <h2 :class="{'gray': loading == true }" v-if="loading == true" class="orderBtnTitle">En traitement</h2>
                            <h2 v-if="loading == false" class="orderBtnTitle">Continuer</h2>                    
                        </div>
                    </v-btn>
                    <v-btn v-if="current && Step == 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class="mt-5 orderBtn" :readonly="loading == true">
                        <div @click="CompletedStep">
                            <h2 :class="{'gray': loading == true }" v-if="loading == true" class="orderBtnTitle">En traitement</h2>
                            <h2 v-if="loading == false" class="orderBtnTitle">Commander</h2>
                        </div>               
                    </v-btn>
                    <v-row class="linkText">
                        <v-col class="navStoreBox" style="">
                            <p class="storeText" style="display:inline;" @click="navigateTo">Continuer son magasinage</p>
                            <p class="backText f-nobold" style="display:inline; padding-left:10px !important;" @click="this.backStep()">Retour</p>
                        </v-col>
                    </v-row>

                    <v-col v-if="loading == true" cols="12">
                        <v-progress-linear class="" color="rgb(var(--secondary))" indeterminate striped height="8"></v-progress-linear>
                    </v-col>

                </div>
            </div>

        </div>
        <div v-if="ready" class="mobile">
            <div class="grid-pad">
                <div>
                    <h2 class="h2Mobile ">Résumé</h2>
                    <div v-if="this.current">
                        <v-row class="mt-10">
                            <v-col cols="8"  class="priceTitle">Sous-Total</v-col>
                            <v-col cols="4"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.subTotal) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"  class="priceTitle">
                                <v-col cols="12">Livraison </v-col>
                            </v-col>
                            <v-col cols="6"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.shipping) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                            <v-col cols="12" class="detailedText" v-if="shippingRestrictiondate">** La date minimale pour la livraison est {{ shippingRestrictiondate }} **</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"  class="priceTitle">TVQ</v-col>
                            <v-col cols="6"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.gst) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"  class="priceTitle">tps</v-col>
                            <v-col cols="6"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.pst) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <hr class="mt-10 f-tierce" style="width:75%; display: block; margin-left: auto !important; margin-right: auto !important;">
                        <v-row class="mt-10">
                            <v-col cols="6"  class="priceTitle">Total</v-col>
                            <v-col cols="6"  class="price">
                                <div v-if="!dirty">{{ $filters.$(this.current.total) }}$</div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                    </div>
                    <div>
                        <v-btn v-if="current && current.items.length == 0 && dirty == false"  class="mt-5 orderBtn">
                            <div @click="navigateTo">        
                                <h2  class="orderBtnTitle">Boutique</h2>
                            </div>
                        </v-btn>
                        <v-btn v-if="current && dirty == true"  class="mt-5 orderBtn">
                            <div @click="reEvaluate">   
                                <h2  class="orderBtnTitle">Rafraichir</h2>
                            </div>
                        </v-btn>
                        <v-btn v-if="current && Step != 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class="mt-5 orderBtn" :readonly="loading == true">
                            <div @click="NextStep">
                                <h2 :class="{'gray': loading == true }" v-if="loading == true" class="orderBtnTitle">En traitement</h2>
                                <h2 v-if="loading == false" class="orderBtnTitle">Continuer</h2>                    
                            </div>
                        </v-btn>
                        <v-btn v-if="current && Step == 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class="mt-5 orderBtn" :readonly="loading == true">
                            <div @click="CompletedStep">
                                <h2 :class="{'gray': loading == true }" v-if="loading == true" class="orderBtnTitle">En traitement</h2>
                                <h2 v-if="loading == false" class="orderBtnTitle">Commander</h2>
                            </div>               
                        </v-btn>
                        <v-row class="linkText">
                            <v-col class="navStoreBox" style="">
                                <p class="storeText" style="display:inline;" @click="navigateTo">Continuer son magasinage</p>
                                <p class="backText f-nobold" style="display:inline; padding-left:10px !important;" @click="this.backStep()">Retour</p>
                            </v-col>
                        </v-row>
                        <v-col v-if="loading == true" cols="12">
                            <v-progress-linear class="" color="rgb(var(--secondary))" indeterminate striped height="8"></v-progress-linear>
                        </v-col>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<style scoped>
    .summary {display: block;}
    .mobile{display: none;}
    .summaryBG { position:fixed; top: 190px; height: auto; width:25%; padding: 20px 0px 20px 0px !important;  background-color: rgba(var(--primary),0.8) !important; z-index: 100;}
    .priceTitle {text-transform: uppercase;  color: rgb(var(--tierce)); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); padding-top: 5px !important;}
    .price {text-align: right; color: rgb(var(--secondary)); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); font-weight: 700;}
    .orderBtn{display: block; margin-left: auto !important; margin-right: auto !important; width: auto; padding: 0px 20px !important; background-color: transparent; border: 1px solid white;}
    .orderBtn:hover {background-color: rgb(var(--secondary));}
    .orderBtnTitle {font-weight: lighter; color: rgb(var(--tierce));}
    .gray {color: dimgray; background-color: gray; border-color: black;}
    .gray:hover {background-color: gray !important; }
    .backText { color: rgb(var(--secondary)); text-decoration: underline; cursor: pointer;}
    .backText:hover {text-decoration: none;}
    .linkText { margin-top:3px !important; margin-left: auto; margin-right: auto; }
    .storeText {color: rgb(var(--secondary)); text-decoration: underline; cursor: pointer; }
    .storeText:hover {text-decoration: none;}
    .h2Mobile{font-family: Ephesis; font-size: 36px; color: rgb(var(--secondary));}
    .detailedText { font-size: 11px; margin-top: -5px !important; text-align: center; text-transform: uppercase;  color: rgb(var(--tierce)); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);}
    .navStoreBox {text-align: center;}
    .navBackBox {text-align: left;}
    .navStoreBoxMobile {text-align: right;}
    .navBackBoxMobile {text-align: left;}
    @media handheld, only screen and (max-width: 600px) {
        .navStoreBoxMobile {text-align: center;}
    .navBackBoxMobile {text-align: center;}
    }
    @media handheld, only screen and (max-width: 958px) {
        .summary {display: none;}
        .mobile{display: block;}
        .backText {color: rgb(var(--primary));}
        .priceTitle {color: rgb(var(--primary));}
        .orderBtnTitle {color: rgb(var(--secondary));}
        .orderBtnTitle:hover {color: rgb(var(--tierce));}
        .storeText {color: rgb(var(--primary));}
        .detailedText {color: rgb(var(--primary)); text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);}
        .navBackBox {text-align: left !important;}
        .linkText { font-size:12px !important;}
    }
    @media handheld, only screen and (max-width: 1200px) {
        .linkText { font-size:11px;}
    }
    @media handheld, only screen and (max-width: 1279px) {
        .navStoreBox {text-align: center;}
        .navBackBox {text-align: center;}
    }

</style>
<script>
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';
  export default {   
    name: 'EcommerceSummary',
    mixins: [apiECommerceExtension],
    props: {
        item: Object,
        step: String,
        dirty: Boolean,
    },
    data: () => ({
        current: null,
        Step: null,
        order:null,
        loading: false,
        shippingRestrictiondate: '',
        
    }),
    computed:{
        ready: async function () {
            let show = false;
            if(this.item) {
                this.current = this.item;
                show = true;
            }
            if(this.step){this.Step = this.step;}
            this.shippingDateValidation();  
            return show;
        },

    },
    methods:{
        NextStep: async function(){      
            this.loading = true;
            let orderUpdated = await this.UpdateOrder(this.item);
            if(this.current.items.length == 0){this.loading = false; this.navigateTo();}
            else{this.loading = false; if(orderUpdated){ this.$emit("update:item", orderUpdated); this.$emit("eCommerceNext", orderUpdated); }}           
        },
        CompletedStep: async function(){  
            this.loading = true;
            let InvoiceRef = await this.CompleteOrder(this.item);
            if(InvoiceRef){ this.$emit("eCommerceCompleted", InvoiceRef);} else {this.$emit("eCommerceCompleted", null);}
            this.loading = false;           
        },
        reEvaluate: async function(){
            this.loading = true;
            let orderUpdated = await this.UpdateOrder(this.item);
            if(orderUpdated) {
                this.$emit("update:item", orderUpdated);
                this.current = orderUpdated;
                this.loading = false;
                this.$emit("update:dirty", false);
            } else {alert('error');}
            
        },
        navigateTo: function () {
            this.$router.push('/Boutique');
        },
        backStep: function(){
            this.$emit("eCommerceBack");
        },
        shippingDateValidation: function(){
            if (this.current) {
                let validation = this.current.validations;
                if (validation) {
                    validation.forEach(e => {
                        if (e.severity == 1) { if (e.field == "DeliveryOn") { if (e.code == "Valid") { this.shippingRestrictiondate = e.message; } } }
                        if (e.severity == 3) { if (e.field == "DeliveryOn") { if (e.code == "Underflow") { this.shippingRestrictiondate = 'dans le passé.'; } } }
                    });
                }
            }
        }

    },
    component: {

    }
  }
</script>