import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Event from '../views/Event.vue'
import Story from '../views/Story.vue'
import WeddingConception from '../views/WeddingConception.vue'
import Conception from '../views/Conception.vue'
import onlineStore from '../views/onlineStore.vue'
import product from '../views/product.vue'
import condition from '../views/Conditions.vue'
import order from '../views/eCommerce/Order/order.vue'
import order_shipping from '../views/eCommerce/Order/order-shipping.vue'
import order_summary from '../views/eCommerce/Order/order-summary.vue'
import order_billing from '../views/eCommerce/Order/order-billing.vue'
import invoice from '../views/eCommerce/invoice.vue'
import paypal_paid from '../views/eCommerce/Paypal/paid.vue'
import paypal_canceled from '../views/eCommerce/Paypal/canceled.vue'
import Policy from '../views/Policy.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Accueil',
    name: 'Accueil',
    component: Home
  },
  {
    path: '/products',
    name: 'Products',
    component: Home
  },
  {
    path: '/produits',
    name: 'Produits',
    component: Home
  },
  {
    path: '/services',
    name: 'Services',
    component: Home
  },
  {
    path: '/Calendrier',
    name: 'Calendrier',
    component: Event
  },
  {
    path: '/event',
    name: 'Event',
    component: Event
  },
  {
    path: '/about',
    name: 'About',
    component: Story
  },
  {
    path: '/story',
    name: 'Story',
    component: Story
  },
  {
    path: '/weddingconception',
    name: 'WeddingConception',
    component: WeddingConception
  },
  {
    path: '/conception',
    name: 'Conception',
    component: Conception
  },
  {
    path: '/condition',
    name: 'condition',
    component: condition
  },
  {
    path: '/conditions',
    name: 'Conditions',
    component: condition
  },
  {
    path: '/onlineStore/:id',
    name: 'onlineStore_ID',
    component: onlineStore
  },
  {
    path: '/onlineStore',
    name: 'onlineStore',
    component: onlineStore
  },
  {
    path: '/Boutique/:id',
    name: 'onlineStore_ID',
    component: onlineStore
  },
  {
    path: '/Boutique',
    name: 'onlineStore',
    component: onlineStore
  },
  {
    path: '/product/:id',
    name: 'product',
    component: product
  }
  ,
  {
    path: '/order',
    name: 'order',
    component: order
  },
  {
    path: '/order/shipping',
    name: 'order_shipping',
    component: order_shipping
  },
  {
    path: '/order/summary',
    name: 'order_summary',
    component: order_summary
  },
  {
    path: '/order/billing',
    name: 'order_billing',
    component: order_billing
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: invoice
  },
  {
    path: '/invoices/:id',
    name: 'invoice_ID',
    component: invoice
  },
  {
    path: '/invoices/:sid/paypal/paid',
    name: 'paypal_paid',
    component: paypal_paid
  },
  {
    path: '/invoices/:sid/paypal/canceled',
    name: 'paypal_canceled',
    component: paypal_canceled
  },
  {
    path: '/PolitiqueDeConfidentialiter',
    name: 'Policy',
    component: Policy
  }
    

]
/*
  {
    path: '/onlineStore',
    name: 'onlineStore',
    component: onlineStore
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
    {
    path: '/onlineStore',
    beforeEnter(to, from, next) {
        
      let path = "/web/page.aspx?Id=39";

      let i = to.fullPath.indexOf("?");
      if(i>=0){ path = path + to.fullPath.substring(i).replace('?','&'); }
      
      //console.log(path);
      window.location.href = path;
    }
  },
  {
    path: '/Boutique',
    beforeEnter(to, from, next) {
        
        let path = "/web/page.aspx?Id=39";

        let i = to.fullPath.indexOf("?");
        if(i>=0){ path = path + to.fullPath.substring(i).replace('?','&'); }
        
        //console.log(path);
        window.location.href = path;
    }
  }
*/

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
})

export default router
