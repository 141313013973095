<template>
    <div>
        <Header />
        <div class="fixingTop"></div>
        <Banner />

        <h1 class="h1Height">Panier</h1>
        <h2 class="soustitre">Voici vos items.</h2>
        <div>
          <v-row>
            <v-col cols="12" md="9" class="" style="">
              <div v-if="this.order">
                <v-row class="grid-pad titleStyle d-none d-sm-flex">
                  <v-col cols="2" class=""></v-col>
                  <v-col cols="5" class="">
                    <div class=""><h2 class="txt-l">Nom</h2></div>
                  </v-col>
                  <v-col cols="1" class="">
                    <div class=""><h2 class="">Prix</h2></div>
                  </v-col>
                  <v-col cols="2" class="">
                    <div class=""><h2 class="">Quantité</h2></div>
                  </v-col>
                  <v-col cols="1" class="">
                    <div class=""><h2 class="">Total</h2></div>
                  </v-col>
                  <v-col cols="1" class="">
                    <div class=""><h2 class="">Retirer</h2></div>
                  </v-col>
                </v-row>
                <div v-if="this.order.items && this.order.items.length > 0" >
                  <v-row  v-for="(item, i) in this.order.items" :key="i" class="ItemsBox">
                    <v-col cols="12" sm="2" class="">
                      <div  class="ItemsImgBox verticalCenter"><img v-if="item.url"  class="ItemsImg" :src="item.url" :truesrc="item.url"/> <img v-else class="ItemsImg" :src="this.image" :truesrc="this.image"/></div>
                    </v-col>
                    <v-col cols="12" sm="5" class="">
                      <div class="">
                        <h2 class="ItemsTitleCenter600">{{item.description}} <p class="ItemsDescription">[{{item.number}}]</p></h2>
                      </div>
                    </v-col>
                    <v-col cols="3" sm="1"  class="">
                      <div class=""><h2 class="txt-c">{{item.price}}$</h2></div>
                    </v-col>
                    <v-col cols="3" sm="2"  class="">
                      <div class=""><h2 class="txt-c">{{item.qty}} </h2></div>
                    </v-col>
                    <v-col cols="3" sm="1"  class="">
                      <div class=""><h2 class="txt-c">{{item.total}}$</h2></div>
                    </v-col>
                    <v-col cols="3" sm="1"  class="">
                      <div class=""><h2 class="txt-c hoverItem" @click="removeItem(i);">X</h2></div>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="orderBoxElse">
                  <h2 class="soustitre mt-10">Aucun Item dans votre panier</h2>
                </div>

              </div>
            </v-col>
            <v-col cols="12" md="3"  class="">
              <Summary step="order" v-model:item="order" v-model:dirty="isDirty" @eCommerceNext="Updated"  @eCommerceBack="back" />
            </v-col>
          </v-row>
        </div>
        <Footer />
    </div>
</template>
<style scoped>
    .h1Height {height:180px;}
    .ItemsBox {position:relative; color: rgba(var(--primary));  height: 100%; background-color: #FFF; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); margin: 20px 20px 30px 20px !important; padding: 40px 0px 40px 0px !important;}
    .ItemsImgBox {height: 90px; width: 90px;  display: inline-block; margin: 0px 0px 0px 15px !important;}
    .ItemsImg {width:100%; height:100%; border-radius: 50%;}
    .verticalCenter{position: absolute; top: 50%; transform: translate(0%, -50%);}
    .titleStyle {height:20px; text-align: center;}
    .hoverItem:hover {color: rgb(var(--secondary)); cursor: pointer;}
    .ItemsDescription {color: rgba(var(--secondary)); font-size: small; margin: -30px 0px 0px 10px !important; display: inline-block;}
    .orderBoxElse {height:100px;}
    @media handheld, only screen and (max-width: 600px) {
      .verticalCenter{ position: relative; top: -20%; transform: none; text-align: center; display: block; margin-left: auto !important; margin-right: auto !important; }
      .ItemsBox {padding: 40px 0px 10px 0px !important;}
      .ItemsTitleCenter600{text-align: center;}
    }
    @media handheld, only screen and (max-width: 958px) {
      .h1Height {height:auto;}
      .soustitre {padding-left: 20px !important; font-size: 32px;}
      h2{font-size: 18px;}
      h3{font-size: 14px;}
    }
    @media handheld, only screen and (max-width: 1264px) { h2{font-size: 1.25em;} .soustitre{font-size: 36px; padding-left: 20px !important;}}
    
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import Summary from '@/components/infoPayment.vue'
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';

export default {
  name: 'Order',
  mixins: [apiECommerceExtension],
  props: {
    summary: Object,
  },
  data: () => ({
    order:null,
    validationObject:[],
    image: 'https://abstracxionstorage.blob.core.windows.net/fbergeron/products/Default.jpg',
    isDirty: false,
    ShippingDate:{min:""},
  }),
  mounted: async function () {
    this.order = await this.GetOrder();
    this.validation();
  },
  methods:{
    removeItem: async function (ItemIndex) {
      this.order = await this.DeleteItem(ItemIndex);
    },
    changeQty: function (ItemIndex,changeId) {
      if(changeId == 1){
        this.isDirty = true;
        this.order.items[ItemIndex].qty = this.order.items[ItemIndex].qty + 1;
        this.order.items[ItemIndex].total = this.order.items[ItemIndex].qty * this.order.items[ItemIndex].price;

      } else if(changeId ==2) {
        this.isDirty = true;
        let qty = this.order.items[ItemIndex].qty - 1;
        if(qty > 0){
          this.order.items[ItemIndex].qty = this.order.items[ItemIndex].qty - 1;
          this.order.items[ItemIndex].total = this.order.items[ItemIndex].qty * this.order.items[ItemIndex].price;
        }else {this.order.items.splice(ItemIndex, 1);}
        
      }
    },
    validation: async function(){
      let valid = false;
      let validation = this.order.validations;
      if(validation){
        valid = true;
        validation.forEach(e => {
          if(e.severity == 3) {
            if(e.Field == "items"){if(e.Code == "Required"){console.log('Required');valid = false;}}
          }
        });
      }
      return valid;
    },
    Updated: async function() {
      let valid = await this.validation();
      if(valid == true){  this.navigateTo(); }
      
    },
    navigateTo: function () {
        this.$router.push('/order/billing');
    },
    back: function(){this.$router.push('/Boutique');},
  },
  components: {
    Header,
    Footer,
    Banner,
    Summary
  },
}
</script>