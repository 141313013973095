<template>
    <div>
        <Header/>
        <div class="fixingTop"></div>
        <Banner/>

        <h1 class="pageTitleHeight">Termes légaux et conditions</h1>
        <v-container class="grid-pad">
            <div>
                <v-row>
                    <ol class="pl-20">
                        <li>
                            <p>
                                <span class="f-primary">Modalités de paiement, taxes de vente et taux de change:</span> Les modalités de paiement sont fixées à l'entière
                                 appréciation de Fleuriste Bergeron et, à moins que Fleuriste Bergeron n'en convienne autrement, ce dernier doit recevoir le paiement 
                                 complet avant d'expédier une commande. Le paiement du produit peut être effectué par carte de crédit ou au moyen d'une autre 
                                 méthode de paiement fixée à l'avance. Fleuriste Bergeron peut annuler votre commande à son entière appréciation. 
                                 Elle n'est pas responsable des erreurs, notamment de prix et typographiques, qui pourraient s'être glissées dans 
                                 l'une de ses offres et se réserve le droit d'annuler les commandes placées sur la foi de telles erreurs. à moins que vous 
                                 ne fournissiez à Fleuriste Bergeron un certificat d'exemption de taxe valide applicable au produit que vous achetez et au 
                                 lieu de destination du produit, vous devez payer la taxe sur les produits et services, les taxes de vente et les autres 
                                 taxes et frais liés à la commande.
                                <br><br>Tous les prix indiqués sont en devise canadienne. Le taux de change pour les commandes facturées aux Etats-Unis (USD) varie selon la valeur de notre devise. 
                            </p>
                        </li>
                        <li>
                            <p>
                                <span class="f-primary">Prix des fleurs :</span> Les prix des fleurs peuvent varier d'une ville à l'autre. 
                                Certains fleuristes peuvent facturer certaines fleurs plus cher. Pour cette raison, il peut s'avérer nécessaire qu'un nombre 
                                supérieur ou inférieur de fleurs soit utilisé, selon la valeur de la commande et de la destination ou elle est envoyée. 
                                Prenez note que tous les prix affichés sont une moyenne des prix chargés par les fleuristes pour l'arrangement choisi. 
                                Dans le cas où un nombre différent de fleurs est reçu par le client, contactez-nous et il nous fera toujours plaisir de 
                                vous expliquer pourquoi cette modification à dû être effectuée. 
                            </p>
                        </li>
                        <li>
                            <p>
                                <span class="f-primary">Garantie :</span> Il se peut que Fleuriste Bergeron ait recours à des produits de remplacement pour que votre arrangement ou votre cadeau soit livré à temps. Soyez assuré que nous accordons la plus grande attention à votre commande afin qu'elle corresponde autant que possible au produit désiré. Nous tentons toujours de conserver l'esprit de l'arrangement suggéré sur la photo. 
                                <br><br><span class="f-primary">Fleurs :</span><br>
                                <ul class="grid-pad">
                                    <li>Pour les arrangements de fleurs assorties, les couleurs figurant sur le site sont utilisées dans la mesure du possible, même si cela nécessite l'utilisation d'autres fleurs de valeur semblable ou supérieure.</li>
                                    <li>Pour les arrangements de fleurs d'une seule variété, comme des roses ou des lys, nous nous efforçons de vous fournir la variété de fleurs désirées, mais il peut s'avérer nécessaire d'avoir recours à une autre couleur.</li>
                                    <li>Si le contenant de fleurs figurant sur le site n'est pas disponible, un contenant semblable de valeur égale ou supérieure est utilisé.</li>
                                </ul>
                                <br><span class="f-primary">Plantes :</span><br>
                                <ul class="grid-pad">
                                    <li>Pour les plantes vertes et fleuries, il peut s'avérer nécessaire d'utiliser des plantes de remplacement de valeur semblable ou supérieure.</li>
                                    <li>Pour les plantes uniques comme des orchidées, nous nous efforçons d'utiliser le type de plante voulu, mais il peut s'avérer que nous ayons recours à une couleur différente.</li>
                                    <li>Si le contenant de la plante figurant sur le site n'est pas disponible, un contenant semblable de valeur égale ou supérieure est utilisé.</li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span class="f-primary">Insatisfaction du produit :</span> En cas d'insatisfaction du produit reçu, notre politique consiste en premier lieu à remplacer les fleurs si le client nous avise dans un délai inférieur à 24 heures suivant la réception des fleurs. Si le client intervient 24 heures suivant la réception des fleurs, un crédit sur la commande suivante pourra être offert. Nous nous réservons le droit de décider du montant dudit crédit. Aucun remboursement ne sera fait, sauf dans l'éventualité où la livraison n'a pas été effectuée pour quelque raison que ce soit. Il est possible que nous vous demandions de nous acheminer une photo de l'article avant d'approuver le retour. 
                            </p>
                        </li>
                        <li>
                            <p>
                                <span class="f-primary">Politique de Livraison :</span> La procédure de livraison pour la plupart des villes consiste à téléphoner le destinataire avant de faire la livraison afin de s'assurer que quelqu'un est sur les lieux. Si vous ne désirez pas que nous téléphonions avant l'envoi, assurez-vous d'abord qu'une personne sera présente sur les lieux à l'heure indiquée, et informez-nous-en lors de votre commande. Dans l'éventualité où personne n'est présent lors d'une telle livraison, un frais supplémentaire pourrait vous être chargé. 
                                <br><br>
                                Fleuriste Bergeron livre dans un rayon de 40 kilomètres. Toute livraison effectuée au-delà de cette limite nécessitera un transfert auprès d'un autre fleuriste impliquant des frais supplémentaires. Un courriel vous informera du coût addtionnel et la façon de l'acquitter.
                                <br><br>                             
                                Pour les envois dans des municipalités extérieures, veuillez effectuer celles-ci par téléphone <span class="cap">UNIQUEMENT</span> au 1-877-477-4709. 
                                <br><br>
                                Nous offrons la possibilité, lors de votre commande, d'indiquer si vous préférez que la livraison soit effectuée en matinée ( AM ) ou en après-midi ( PM ). Vous pouvez également mentionner une heure spécifique dans les instructions spéciales. Ces informations seront prises en considération, mais demeurent facultatives. Il est possible que la livraison soit faite plus tôt ou plus tard, dépendant du volume de livraisons à faire pour cette journée. En aucun cas nous ne serons tenus responsables des retards une fois la livraison complétée et aucun remboursement se fera pour cette raison. Soyez assuré cependant que nous nous engageons à compléter chaque commande avec professionnalisme et avec le plus grand égard pour votre commande. 
                            </p>
                        </li>
                        <li>
                            <p>
                                <span class="f-primary">Produit non livré :</span> En cas d'une commande non livrée ou de l'absence d'un item choisi dans la commande, ( ballon, carte, vase ou toutou ), avisez-nous et il nous fera plaisir de rembourser la valeur de l'item sur votre carte de crédit. Veuillez prendre note que pour des fins de sécurité, tous les numéros de carte de crédit donnés que nous recevons sont automatiquement effacés de nos bases de données une fois traités. Vous devrez donc nous fournir votre numéro de carte de crédit une seconde fois, par téléphone. 
                                <br><br>
                                Dans l'éventualité où votre commande n'est pas livrée le jour désiré, veuillez nous aviser et il nous fera plaisir de vous expliquer pourquoi celle-ci a dû être reportée.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span class="f-primary">Autres documents :</span> La présente convention NE peut PAS être modifiée par l'utilisation d'un ou de plusieurs autres documents, sauf si vous vous entendez autrement par écrit avec Fleuriste Bergeron. Si vous ne recevez pas de facture ou d'accusé de réception par la poste, par courriel ou avec votre produit, vous pouvez obtenir de l'information au sujet de votre achat en ligne à l'adresse info@fleuristebergeron.com ou en communiquant avec nous directement. 
                            </p>
                        </li>
                    </ol>
                </v-row>
            </div>
        </v-container>

        <Footer/>
    </div>
</template>
<style scoped>
    .pl-20 {padding-left: 20px !important;}
    p{text-align: justify; margin: 30px 0px !important;}
    .v-container {margin: 50px 0px 20px 0px !important; max-width: none !important;}
    .pageTitleHeight{font-size: 140px;}
    .cap {text-transform: uppercase;}
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {.pageTitleHeight{font-size: 50px !important;}}
    @media handheld, only screen and (max-width: 1264px) {
        .v-container {max-width: 1800px !important;}
        .pageTitleHeight{font-size: 100px;}
    }
    @media handheld, only screen and (max-width: 1920px) {
        .v-container {max-width: 1920px !important;}
    }
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'/*
import VueSlickCarousel from 'slick-vuejs'
import 'slick-vuejs/dist/slick-vuejs.css'
// optional style for arrows & dots
import 'slick-vuejs/dist/slick-vuejs-theme.css'*/

export default {
  name: 'Event',
  data: () => ({
    Items:[
        {src:'Resource/WeddingConception/Photo1.jpg'},
        {src:'Resource/WeddingConception/Photo2.jpg'},
        {src:'Resource/WeddingConception/Photo3.jpg'},
        {src:'Resource/WeddingConception/Photo1.jpg'},
        {src:'Resource/WeddingConception/Photo2.jpg'},
        {src:'Resource/WeddingConception/Photo3.jpg'},
        {src:'Resource/WeddingConception/Photo1.jpg'},
        {src:'Resource/WeddingConception/Photo2.jpg'},
        {src:'Resource/WeddingConception/Photo3.jpg'},
    ],
    model: null,
  }),
  mounted: async function () {
    
  },
  methods:{

  },
  components: {
    Header,
    Footer,
    Banner,

},
}
</script>