<template>
    <div id="cookie" >
        <div class="overlayCookie">
          <div class="cookieBox cookiePad">
            <div class="cookieTextPad">
              <h2 class="txt-c GeneralTitle">Nous utilisons des témoins</h2>
              <div class="cookieText">
                <p>Votre vie privée est une priorité pour nous. Nous utilisons des témoins (cookies) et d'autres technologies similaires pour personnaliser nos contenus et améliorer votre expérience en ligne. Certains de ces témoins (cookies) peuvent être collectés avec votre consentement préalable.</p>
                <div class="cookieLink"><p @click="navigateToPolicy()" class="txt-r">Politique de confidentialité</p></div>
              </div>
              <div class="mt-5">
                <button class="btnCookie flt-r" @click="cookieChoice(1);">Tout Accepter</button>
                <button class="btnCookie flt-r" @click="cookieChoice(3);">Personalisé vos témoins</button>
              </div>
            </div>            
        </div>
        <div class="cookiePersonalise">
            <div class="">
              <div class="">
                <div><h2 class="txt-c GeneralTitle">Personalisé vos témoins</h2></div>
                <div class="cookiePersonaliseInfo">     
                  <div><!--Essentiel-->
                    <div><h2 class="cookiePersionalisationTitle">Témoins nécessaires</h2></div>
                    <div><p class="cookiePersionalisationTexte">Ces témoins vous permettent d’apprécier toutes les fonctionnalités de notre site et d’y naviguer de manière optimale et sécuritaire. Ils nous servent aussi à vous demander votre opinion et à évaluer votre satisfaction.</p></div>
                    <div><p class="cookiePersionalisationTexte f-bold txt-r">Ces témoins sont obligatoires.</p></div>
                    <div class=""><hr></div>
                  </div>
                  <div><!--Performance-->
                    <div><h2 class="cookiePersionalisationTitle">Témoins de performances</h2></div>
                    <div><p class="cookiePersionalisationTexte">Grâce à l'emploi de ces témoins (cookies), nous avons la possibilité d'examiner la manière dont nos sites sont utilisés en vue d'améliorer leur performance et leur fonctionnement.</p></div>
                    <div class="switchContainer"><div class="switch off" @click="toggleSwitch('Performance')" :class="{ 'on': authorizationCookies['Performance'], 'off': !authorizationCookies['Performance'] }"><div class="slider"></div></div></div>
                    <div><hr></div>
                  </div>
                  <div> <!--Personnalisation-->
                    <div><h2 class="cookiePersionalisationTitle">Témoins de personnalisation</h2></div>
                    <div><p class="cookiePersionalisationTexte">Grâce à ces témoins (cookies), nous sommes en mesure de retenir vos préférences et d'ajuster les contenus proposés en fonction de votre historique de navigation et de vos choix précédents.</p></div>
                    <div class="switchContainer"><div class="switch off" @click="toggleSwitch('Personalization')" :class="{ 'on': authorizationCookies['Personalization'], 'off': !authorizationCookies['Personalization'] }"><div class="slider"></div></div></div>
                    <div><hr></div>
                  </div>
                  <div><!--pub-->
                    <div><h2 class="cookiePersionalisationTitle">Témoins de publicité ciblée</h2></div>
                    <div><p class="cookiePersionalisationTexte">Ces témoins (cookies) contribuent à restreindre la fréquence d'affichage des publicités, à adapter nos offres et services à vos centres d'intérêt, à évaluer l'efficacité de campagnes publicitaires, entre autres usages. Il est possible que nous partagions ces données avec nos partenaires.</p></div>
                    <div class="switchContainer"><div class="switch off" @click="toggleSwitch('Advertising')" :class="{ 'on': authorizationCookies['Advertising'], 'off': !authorizationCookies['Advertising'] }"><div class="slider"></div></div></div>
                    <div><hr></div>
                  </div>
                  <div class="cookieLink"><p @click="navigateToPolicy()" class="txt-r">Politique de confidentialité</p></div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btnCookie flt-r" @click="cookieChoice(1);">Tout Accepter</button>
                <button class="btnCookie flt-r" @click="cookieChoice(2);">Accepter</button>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<style scoped>
    @import '../../public/App_Themes/Main/Main.css';
    @import '../../public/App_Themes/Main/Theme.css';    
    /*#region cookie*/
        .cookiePad {padding: 10px !important;}
        .cookieTextPad {padding: 20px 100px !important;}
        .GeneralTitle { font-family: Ephesis; font-size: 36px; }
        .cookieTitle {font-family: Ephesis; font-weight: 700; font-size: 64px !important; height: 64px; padding: 15px 10px !important; text-align: center;}
        .cookiePersionalisationTitle { font-size: 24px; padding: 25px !important; font-family: sans-serif;}
        .cookiePersionalisationTexte{ font-size: 14px; padding: 10px 50px !important; font-family: sans-serif;}
        .cookieText {padding: 0px 200px !important;}
        .cookiePersonaliseInfo {padding: 0px 50px !important;}
        .cookieLink { color: rgb(var(--primary)) !important; text-decoration: none !important; cursor: pointer;}
        .cookieLink:hover { text-decoration: underline !important; }
        .btnCookie {padding: 10px !important; text-transform:uppercase; margin: 5px !important; border: 1px solid rgb(var(--primary)); color: rgb(var(--primary)) !important; cursor: pointer; border-radius: 5px;}
        .btnCookie:hover { background-color: rgb(var(--primary)) !important; color: rgb(var(--secondary)) !important; }
        .overlayCookie { display: none; position: absolute; width: 100%; height: 100%; z-index: 100000; background-color: rgba(000,000,000,0.2); }
        .cookieBox { display: none; position: fixed; bottom: 0; width: 100%; height: auto; background-color: white;}
        .cookiePersonalise { display: none; overflow-y:scroll; position: fixed; z-index: 100000; width: auto; max-width: 75%; left: 50%; transform: translate(-50%, 0%); height: 100%; max-height:100%; background-color: white;}
        .switchContainer {height: 30px;}
        .switch {display: inline-block; width: 50px; height: 20px; background-color: #ccc; border-radius: 15px; position: relative; float: right; cursor: pointer; }
    
        /* Styles pour la poignée (slider) */
        .slider { width: 20px; height: 20px; background-color: black; border-radius: 50%; position: absolute; transition: 0.2s; }
    
        /* Position de la poignée lorsque le v-switch est désactivé */
        .switch.off .slider { transform: translateX(0); }
        /* Position de la poignée lorsque le v-switch est activé */
        .switch.on {background-color: rgba(var(--primary),0.2);}
        .switch.on .slider { background-color: rgb(var(--primary)); transform: translateX(30px); }
        .flt-r {float: right;}
    /*#endregion */
    @media handheld, only screen and (max-width: 960px) {

    /*#region cookie*/
        .cookiePad {padding: 5px !important;}
        .cookieTextPad {padding: 10px 10px !important;}
        .cookieTitle {padding: 5px 0px !important;}
        .cookiePersionalisationTitle { font-size: 24px; padding: 10px !important; }
        .cookiePersionalisationTexte{ font-size: 14px; padding: 5px 10px !important;}
        .cookieText {padding: 0px 10px !important;}
        .cookiePersonaliseInfo {padding: 0px 10px !important;}
        .cookiePersonalise {  max-width: 100%; left: 0%; transform: translate(0%, 0);}
        .GeneralTitle {font-size: 32px; margin-top: 70px !important;}
    /*#endregion */
}
</style>
<script>
  export default {
    name: 'Cookie',
    data: () => ({
        element: null,
        authorizationCookies: {
            Essential: true,
            Performance: false,
            Personalization: false,
            Advertising: false,
        }
    }),
    mounted: async function () {
        $App.event.$on("openCookie", this.openWindow);
        this.element = document.getElementById("cookie");
        let authorizations = JSON.parse(localStorage.getItem("authorizationCookies"));
        if(authorizations){
            this.authorizationCookies = authorizations;
        } else { 
            this.element.getElementsByClassName('cookieBox')[0].style.display = 'block';
            this.element.getElementsByClassName('overlayCookie')[0].style.display = 'block';
        }
    },
    methods:{
        cookieChoice: function (choiceMade){
            if(choiceMade == 1){
                this.ShowCookieWindow = false;
                this.element.getElementsByClassName('cookieBox')[0].style.display = 'none';
                this.element.getElementsByClassName('overlayCookie')[0].style.display = 'none';
                
                this.authorizationCookies['Performance'] = true;
                this.authorizationCookies['Personalization'] = true;
                this.authorizationCookies['Advertising'] = true;
                localStorage.setItem('authorizationCookies', JSON.stringify(this.authorizationCookies));
            }
            else if(choiceMade == 2){
                this.element.getElementsByClassName('cookiePersonalise')[0].style.display = 'none';
                this.element.getElementsByClassName('overlayCookie')[0].style.display = 'none';
                localStorage.setItem('authorizationCookies', JSON.stringify(this.authorizationCookies));
            }
            else{ 
                this.element.getElementsByClassName('cookieBox')[0].style.display = 'none';
                this.element.getElementsByClassName('cookiePersonalise')[0].style.display = 'block';
            }
        },
        toggleSwitch: function(index) {
            this.authorizationCookies[index] = !this.authorizationCookies[index];
        },
        navigateToPolicy: function(){
            const element = document.getElementById("cookie");
            element.getElementsByClassName('cookieBox')[0].style.display = 'none';
            element.getElementsByClassName('overlayCookie')[0].style.display = 'none';
            
            localStorage.setItem('authorizationCookies', JSON.stringify(this.authorizationCookies));
            this.$router.push('/PolitiqueDeConfidentialiter');
        },
        openWindow: function(){
            this.element.getElementsByClassName('cookieBox')[0].style.display = 'block';
            this.element.getElementsByClassName('overlayCookie')[0].style.display = 'block';
        }
    },
    component: {

    }
  }
</script>