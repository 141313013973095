<template>
    <div>
        <Header/>
        <div class="fixingTop"></div>
        <Banner/>
        <div class="grid-pad">
          <h1 class="titleMargin">{{Product.title}}</h1>
          <v-container class="marginCointainer">
            <v-row>
              <v-col  cols="12" sm="4" class="rel" >
                <v-container id="image">
                  <div v-if="this.image" class="whiteBG"><v-img class="productImg" :src="this.image"></v-img></div>                 
                </v-container>
              </v-col>
              <v-col  cols="12" sm="8" class="rel">
                <div class="borderLeft"></div>
                <div class="marginText">
                  <div class="widthText">
                    <h2 class="secondaryTitle">{{Product.title}}</h2>
                    <p class="back">[{{Product.accountingNumber}}]</p>
                    <p class="ProductDescription">{{Product.description}}</p>
                    <p class="price">{{ $filters.demo(Product.price) }}<span v-if="Product.price">$</span></p>
                    <p class="back" @click="navigateBack()">Retour</p>
                  </div>     
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
          <div class="purpleBanner waterMarkFB">
            <v-row class="">
              <v-col cols="12">
                <div class="textProductBanner">
                  <v-row class="grid grid-pad">
                    <v-col cols="7" sm="5" md="8" lg="9" class="whiteLine d-none d-sm-flex"  ></v-col>
                    <v-col cols="12" sm="7" md="4" lg="3"  style=""><h2 @click="AddItemInOrder()" class="whiteText" v-if="Product.price">Ajouter au panier</h2><h2 class="whiteTextDemo" v-else>Passer en magasin</h2></v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        <Footer/>
    </div>
</template>
<style>
  #image .v-img__img--contain {object-fit: scale-down !important; object-position: center center;}
  #image .v-responsive {overflow: unset !important;}
</style>
<style scoped>
  .titleMargin {margin-bottom:35px !important;}
  .v-container {max-width: 100%;}
  .rel {position: relative;}
  .widthText{width: 75%; display: block; margin-left: auto !important; margin-right: auto !important; text-align: center;}
  .waterMarkFB{ background-image: url("../../public/Resource/filigramFBH200.png"); background-position-x: 6%;}
  .secondaryTitle {color: rgba(var(--primary));  margin-top: 100px !important; font-size: 36px; }
  #image{ width: 100%; height: 100%; max-height: 800px;  background-image: url('../../public/Resource/onlineStore/Default.jpg'); background-position: center center; background-repeat: no-repeat; background-size: contain; }
  .whiteBG { height: 100%; width: 100%; background-color: white; }
  .productImg { width: 100%; height: 100%; max-width: 700px; max-height: 800px; object-fit: scale-down !important; object-position: center center;}
  /*.productImg {height: 100%; width: 100%; max-width: 800px; position:absolute; left: 0;}*/
  .ProductDescription {font-weight: lighter !important; font-size: 24px; text-align: center; margin-top: 100px !important;}
  .price { font-weight: 700 !important; font-size: 40px;  margin-top: 50px !important; color: rgba(var(--secondary)); text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);}
  .purpleBanner{height: 200px; width: 100%;  background-color: rgba(var(--primary),0.5); z-index:0; overflow-x: hidden;}
  .borderLeft { position: absolute; border-left: 1px solid rgba(var(--secondary),0.5); height: 250px; left:100px; top:50px;}
  .textProductBanner{height: 50px; margin: 70px 0px 0px 0px !important;}
  .text { position: absolute; left:50%; top:50%; transform: translate(-50%, -50%) }
  .whiteLine { border-top: 1px solid white; margin-top: 44px !important; }
  .whiteText { font-family: Ephesis; color:white; text-align: right; font-size: 50px; }
  .whiteTextDemo { font-family: Ephesis; color:white; text-align: right; font-size: 50px; }
  .whiteText:hover {cursor: pointer; color: rgb(var(--secondary));}
  .back{ color: rgb(var(--secondary)); cursor: pointer; }
  .marginText {margin:100px !important;}
  .marginCointainer {margin-top:10px !important; margin-bottom:10px !important;}
  @media handheld, only screen and (max-width: 600px) {
    .whiteText {text-align: center; font-size: 40px; }
    .waterMarkFB{ background-image: url("../../public/Resource/filigramFBH200.png"); background-size: contain; background-position: center bottom;}


    #image{height: 300px;}
  }
  @media handheld, only screen and (max-width: 960px) {
    h1 {font-size: 48px !important; height: auto;}
    .borderLeft { display: none;}
    .ProductDescription {font-size: 18px; margin-top: 10px !important;}
    .secondaryTitle {color: rgba(var(--primary));  margin-top: 10px !important; font-size: 24px; }
    .marginText {margin: 0px !important;}
  }
  @media handheld, only screen and (max-width: 1264px) {}
    
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'

export default {
  name: 'Product',
  mixins: [apiECommerceExtension],
  data: () => ({
    Product: {title:"product"} ,
    image: 'https://abstracxionstorage.blob.core.windows.net/fbergeron/products/Default.jpg',
  }),
  mounted: async function () {
    this.Product = await this.EcommerceProductGetById();
    if(this.Product.image.url){this.image = this.Product.image.url;}
  },
  methods:{
      navigateBack: function (route) {
        if (window.history.length > 1) {
            this.$router.go(-1);
        } else if (route) {
            this.$router.push(this.$router.push({ name: 'onlineStore'}));
        }
      },
      AddItemInOrder: async function(){
        let added = await this.AddItem(this.Product);
        if(added == true){
          this.$router.push({ name: 'order' });
        }
      },
  },
  components: {
    Header,
    Footer,
    Banner
  },
}
</script>