<template>
  <div>
    <Header/>
      <div class="fixingTop"></div>
      <div class="topBanner">
          <div class="topBannerColor"></div>
      </div>
     <!--Section1 Staff-->
     <section class="grid-pad">    
      <div class="textPad">
        <h1 class="policyTitle">Politique de confidentialité</h1>
        <v-row >
          <v-col>      
            <p >Fleuriste Bergeron est soucieuse de la protection de votre vie privée et des renseignements personnels qu’elle recueille sur son site Web ou tout autre moyen technologique, utilise ou communique à des tiers à l’occasion de ses activités.</p>
            <p>La présente Politique de confidentialité fait état de la manière dont Fleuriste Bergeron protège les renseignements personnels qu’elle détient, et ce, afin de tenir compte des exigences des lois applicables en matière de protection des renseignements personnels auxquelles elle est assujettie, mais aussi de toutes autres lois et règlements applicables en ce domaine.</p>
            <p>La présente Politique ne s’applique pas aux renseignements qui ont un caractère public en vertu de ces législations.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Responsabilité</h2>
            <p>Fleuriste Bergeron est responsable de la protection des renseignements personnels qu’elle détient ou qu’elle confie, le cas échéant, à un tiers.</p>
            <p>Fleuriste Bergeron s’assure que ses employés, mais aussi les tiers avec qui elle fait affaire protègent la confidentialité des renseignements personnels auxquels ils ont accès, et ce, quel que soit la nature de leur support ou quelle que soit la forme sous laquelle ils sont accessibles. </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="txt-c">Renseignements personnels que nous recueillons</h2>
            <p>Fleuriste Bergeron ne collecte aucun renseignement personnel visant à être transmis. Les données suivantes sont collectées uniquement pour nos bons de commandes:</p>
            <ul class="grid-pad f-primary">
              <li>Nom complet</li>
              <li>Date</li>
              <li>téléphone</li>
              <li>Courriel</li>
              <li>Adresse</li>
              <li>Commentaires</li>
            </ul>
            <p>Les données obtenues vous concernant ne visent qu’à compléter nos bons de commandes afin de garantir le service auquel vous vous attendez de recevoir. </p>
          </v-col>
        </v-row>
        <v-row class="mt-16">
          <v-col cols="12" class="d-lg-flex flex-lg-row">
            <v-col style="position:relative;">
              <div class="section1Photo4"></div>
              <h2 class="txt-c section1TextLogo" style="">Responsable de la protection <br> des renseignements personnels</h2>
            </v-col>
            <v-col class="txt-c">
              <p class="section1Text3 f-primary">Pierre Nadeau</p>
              <p class="section1Text2 mt-8">1380 rue Hébert</p>
              <p class="section1Text2">Drummondville, Québec, J2C 1Z9</p>
              <p class="section1Text2 f-nobold">Téléphone: (819) 477-4700</p>
            </v-col>
          </v-col>
          <v-col>
            <p>Le responsable s'engage à garantir la protection des données personnelles collectées, à ne pas les divulguer à des tiers sans votre consentement préalable, et à respecter les objectifs pour lesquels elles ont été collectées. De plus, il vous informera dans les plus brefs délais en cas de compromission de la confidentialité de vos données personnelles.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Demande d'accès et de rectifications</h2>
            <p> De plus, vous avez la possibilité de soumettre une demande d'accès pour connaître les renseignements personnels détenus par Fleuriste Bergeron et de demander des rectifications si nécessaire.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="txt-r">Durée de conservation</h2>
            <p>Nous conservons vos renseignements personnels pour une durée de cinq années suivant leur dernière utilisation sauf lorsque la loi prévoit une durée de conservation différente.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Mesures de sécurité</h2>
            <p>Nous mettons en place des normes de sécurité appropriées, incluant des mesures physiques, techniques et administratives, pour garantir la protection de vos renseignements personnels. Ces mesures visent à prévenir tout accès non autorisé, utilisation, divulgation à des tiers, ainsi que toute perte ou atteinte à la sécurité de ces renseignements.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-coL>
            <h2 class="txt-r">Utilisation de témoins (cookies)</h2>
            <p>Des témoins, également connus sous le nom de cookies, peuvent être utilisés pour simplifier la navigation sur les pages de ce site. Il est important de noter que ces témoins peuvent recueillir des données personnelles vous concernant. Vous avez la possibilité à tout moment de configurer votre navigateur pour qu'il bloque l'utilisation de ces fichiers témoins. Cependant, veuillez noter que cette action pourrait vous priver de certaines des fonctionnalités offertes sur le site.</p>
            <p>En outre, Google Analytics peut recourir à des fichiers témoins pour acquérir des informations sur votre navigation dans le but d'améliorer votre expérience à des fins statistiques. Ces données sont généralement sauvegardées aux États-Unis, et Google peut les partager avec des tiers en cas d'obligation légale ou lors de leur traitement en son nom. Ces informations sont également susceptibles d'être partagées sur les comptes Google Ads et Google Search Console de la compagnie. Parmi les informations recueillies, on trouve notamment :</p>
              <ul>
                <li><p>L'adresse IP de votre ordinateur, qui est anonymisée pour empêcher toute identification directe.</p></li>
                <li><p>Le système d'exploitation de votre appareil.</p></li>
                <li><p>Les pages que vous consultez, incluant la date, l'heure, la durée et la fréquence de vos visites.</p></li>
              </ul>   
          </v-coL>
        </v-row>
        <v-row>
          <v-col>
            <h2 >Nous contacter</h2>
            <p>Pour toute question concernant notre Politique de confidentialité, pour partager vos commentaires, exercer vos droits, ou déposer une plainte, veuillez contacter Pierre Nadeau au numéro suivant :(819) 477-4700.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="txt-c">Mise à jours de la politique</h2>
            <p>Les informations de cette politique ont été révisées et actualisées le 22 septembre 2023 afin de maintenir leur pertinence et leur exactitude. Si vous avez des questions, des préoccupations ou des commentaires relatifs à notre Politique de confidentialité, veuillez contacter notre Responsable de la Protection des Données Personnelles. Vous pouvez le joindre en utilisant les coordonnées fournies dans la politique ou en utilisant les canaux de communication spécifiés à cet effet. Votre satisfaction et la protection de vos données personnelles sont au cœur de nos préoccupations.</p>
          </v-col>
        </v-row>
      </div>

      </section>

    <Footer/>
  </div>
</template>

<style scoped>
  .policyTitle {font-size: 72px;}
  .fixingTop {height: 150px;}
  .topBanner {height: 500px; background-image: url('../../public/Resource/Banner/bannerPolicy.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}
  .topBannerColor { height: 500px; width: 100%; background-color: rgba(var(--secondary),0.5);}
  .textPad { padding: 0px 200px !important;}
  p { font-size:18px; text-align:justify; color: rgb(var(--secondairy)); margin: 15px 0px 15px 0px !important;}
  ul {padding: 0px 50px !important;}
  .section1Text {font-size:40px; text-align:center; color: rgb(var(--secondairy));}
  .section1Text2 {font-size:18px; text-align:center; color: rgb(var(--secondairy));}
  .section1Text3 {font-size:36px; font-weight:700; text-align:center; margin: 0px 0px 0px 0px !important;}
  .section1TextLogo {position:absolute; width:100%; top:50%; left:50%; transform:translate(-50%, -50%); font-family:Ephesis; font-weight:700; font-size:40px;}
  /*.section1Photo4 {height:350px; background-image: url('../../public/Resource/images/handwritten-letter-vectorportal.png'); background-repeat: no-repeat; background-position: center center; background-size: 230px 230px; opacity:0.2;}*/
  @media handheld, only screen and (max-width: 1330px) {
    .textPad { padding: 0px 100px !important;}
    .section1TextLogo {position:relative; width:100%; top:50%; left:50%; transform:translate(-50%, -50%); font-family:Ephesis; font-weight:700; font-size:32px;}
  }
  @media handheld, only screen and (max-width: 960px) {
    .textPad { padding: 0px 10px !important;}
    .section1TextLogo {position:relative; width:100%; top:50%; left:50%; transform:translate(-50%, -50%); font-family:Ephesis; font-weight:700; font-size:32px;}
    .section1Text3 {font-size:36px; font-weight:700; text-align:center; margin: 0px 0px 0px 0px !important;}
    h2 {font-size: 32px;}
    h3 {font-size: 32px;}
    .policyTitle {font-size: 32px;}
    
  }
  @media handheld, only screen and (max-width: 535px) {}
  @media handheld, only screen and (max-width: 513px) {}
  @media handheld, only screen and (max-width: 475px) {}
</style>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Diapo from '@/components/Diapo.vue'
export default {
  name: 'Policy',
  data: () => ({

  }),
  mounted: async function () {

  },
  methods:{

  },
  components: {
    Header,
    Footer,
    Diapo,
  },
}
</script>