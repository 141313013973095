<template>
  <div>
    <Header/>
    <div class="fixingTop"></div>
    <Banner/>
    <h1 class="h1Height">Paiement</h1>
    <div v-if="invoice">
      <h2  v-if="invoice.balance != 0 && !invoice.paidOn" class="soustitre">Voici l'étape finale pour terminer votre achat.</h2>
      <h2  v-else class="soustitre">Votre facture <span class="invoiceRefTitle">{{ this.invoice.uId }}</span> à été payée. <br>Un courriel vous a été transmis renfermant les détails complets. <br>Nous vous remercions vivement d'avoir choisi Fleuriste Bergeron. </h2>
    </div>

    <!--Section1-->
    <section id="sectionPaiement">
    <div v-if="invoice " class="grid grid-pad">
      <v-row>
        <v-col cols="12" md="9" class="mt-10 mb-10" style="">
            <div v-if="invoice.balance != 0">
              <div>
                <h2 class="subtitleSum">Information de la facture</h2>
                <div class="grid-pad mt-10">

                  <v-row>
                    <v-col cols="12" sm="6" class="grid-pad">
                      <v-row class="infoItem">
                        <v-col cols="12" sm="6" class="ItemTitle">Nom:</v-col>
                        <v-col cols="12" sm="6" ><p class="ItemTexte">{{ invoice.accountName }}</p></v-col> <!--{{this.order.Client.Name}}-->
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="6" class="grid-pad">
                      <v-row class="infoItem">
                        <v-col cols="12" sm="6" class="ItemTitle">Numéro de référence:</v-col>
                        <v-col cols="12" sm="6" ><p class="ItemTexte">{{ invoice.uId }}</p></v-col><!--{{this.order.InvoiceRef}}-->
                      </v-row>
                    </v-col>
                  </v-row>
                </div>

              </div>
              <div v-if="this.invoice" class="mt-10">
                <h2 class="subtitleSum" >Items</h2>

                <div class="ItemSummary" v-if="invoice.items">
                  <v-row  v-for="(item, i) in invoice.items" :key="i" class="grid-pad ItemsBox">

                      <v-col cols="5"><p><span class="f-secondary">{{item.number}}</span><span class="itemPrice">({{item.price}}$)</span></p></v-col>
                      <v-col cols="6"><p> <span class="f-primary right">{{item.total}} $</span></p></v-col>
                      <v-col cols="12"><p v-html="item.description.replace('\r\n','<br>')"></p></v-col>
                  </v-row>
                </div>
                <div class="ItemSummary" v-else>
                  <h2 class="subtitleSum">Aucun Item dans votre panier</h2>
                </div>
              </div>
            </div>

        </v-col>
        <v-col cols="12" md="3">
          <div  v-if="invoice.balance != 0" class=" ">
            <div class="summaryBG summary">
                <div class="grid-pad">
                  <h2 class="mt-2 sectionTitle">Résumé</h2>
                  <!--<h2 class="titleStyle">Choix du paiement</h2>-->
                  <div v-if="this.invoice">
                    <v-row>
                        <!-- <v-btn-toggle v-model="invoice.paymentTypeId"  color="rgb(var(--secondary))" >
                          <v-btn value="0"><img style="height: 35px; width: auto; " src="/Resource/paypal.png" /></v-btn>
                          <v-btn value="1"><img style="height: 35px; width: auto;" src="/Resource/creditcard.png" /></v-btn>
                        </v-btn-toggle> -->
                        <v-radio-group :disabled="loading" data-axId="invoice-grpPaymentTypeId" v-model.number="invoice.paymentTypeId" :messages="paymentTypeMessage">
                          <v-radio :value="7"> <template v-slot:label><img src="/Resource/paypal.png" /></template> </v-radio>
                          <v-radio :value="3"> <template v-slot:label> <img src="/Resource/creditcard.png" /></template> </v-radio>
                        </v-radio-group>
                    </v-row>
                    <v-row class="mt-1">
                        <v-col cols="6"  class="priceTitle">
                            <v-col cols="12">Sous-Total</v-col>
                        </v-col>
                        <v-col cols="6"  class="price">{{$filters.$(invoice.subTotal)}}$</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"  class="priceTitle">
                            <v-col cols="12">Livraison </v-col>
                        </v-col>
                        <v-col cols="6"  class="price">{{$filters.$(invoice.shipping)}}$</v-col>
                    </v-row>
                    <v-row >
                        <v-col cols="6"  class="priceTitle">TPS</v-col>
                        <v-col cols="6"  class="price">{{$filters.$(invoice.gst)}}$</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"  class="priceTitle">TVQ</v-col>
                        <v-col cols="6"  class="price">{{$filters.$(invoice.pst)}}$</v-col>
                    </v-row>
                    <hr class="mt-5 hr">
                    <v-row class="mt-5">
                        <v-col cols="6"  class="priceTitle">Total</v-col>
                        <v-col cols="6"  class="price">{{ $filters.$(invoice.balance) }}$</v-col>
                    </v-row>
                  </div>
                  <div v-if="invoice">
                    <v-btn v-if="invoice.balance != 0 && loading == false && !invoice.paidOn" class="mt-5 orderBtn clicker" @click="payInvoice()">
                      <h2 class="orderBtnTitle" >Payer</h2>
                    </v-btn>
                    <div v-if="loading == true">
                      <h2  class="sectionTitle" style="text-align: center;">En traitement</h2> <!--nedd the style for text-align here-->
                      <v-progress-linear color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                        <p class="" style="font-weight: 700; cursor: pointer; text-align: center; color: rgb(var(--primary));" @click="cancelInvoice()">Recommencer le paiement</p>
                    </div>
                  </div>
                </div>
            </div>

          </div>
          <div v-if="invoice.balance != 0" class="mobile">
              <div class="grid-pad">
                  <div>
                      <h2 class="h2Mobile ">Résumé</h2>
                      <!--<h2 class="titleStyle mt-5">Choix du paiement</h2>-->
                      <div v-if="this.invoice">
                        <v-row>
                          <v-radio-group :disabled="loading" data-axId="invoice-grpPaymentTypeId" v-model.number="invoice.paymentTypeId" :messages="paymentTypeMessage">
                            <v-radio :value="7"> <template v-slot:label><img src="/Resource/paypal.png" /></template> </v-radio>
                            <v-radio :value="3"> <template v-slot:label> <img src="/Resource/creditcard.png" /></template> </v-radio>
                          </v-radio-group>
                          </v-row>
                        <v-row class="mt-5">
                            <v-col cols="6"  class="priceTitle">
                                <v-col cols="12">Sous-Total</v-col>
                            </v-col>
                            <v-col cols="6"  class="price">{{$filters.$(invoice.subTotal)}}$</v-col>
                        </v-row>
                        <v-row >
                            <v-col cols="6"  class="priceTitle">
                                <v-col cols="12">Livraison </v-col>
                            </v-col>
                            <v-col cols="6"  class="price">{{$filters.$(invoice.shipping)}}$</v-col>
                        </v-row>
                        <v-row >
                            <v-col cols="6"  class="priceTitle">TPS</v-col>
                            <v-col cols="6"  class="price">{{$filters.$(invoice.gst)}}$</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"  class="priceTitle">TVQ</v-col>
                            <v-col cols="6"  class="price">{{$filters.$(invoice.pst)}}$</v-col>
                        </v-row>

                        <hr class="mt-10 hr">
                        <v-row class="mt-10">
                            <v-col cols="6"  class="priceTitle">Total</v-col>
                            <v-col cols="6"  class="price">{{ $filters.$(invoice.balance) }}$</v-col><!--{{this.order.summary.Total}}-->
                        </v-row>
                      </div>

                      <div v-if="invoice">
                        <v-btn v-if="invoice.balance != 0 && loading == false && !invoice.paidOn" class="mt-5 orderBtn clicker" @click="payInvoice()">
                          <h2 class="orderBtnTitle" >Payer</h2>
                        </v-btn>
                        <div v-if="loading == true">
                          <h2  class="sectionTitle" style="text-align: center;">En traitement</h2> <!--nedd the style for text-align here-->
                          <v-progress-linear color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                            <p class="" style="font-weight: 700; cursor: pointer; text-align: center; color: rgb(var(--primary));" @click="cancelInvoice()">Recommencer le paiement</p>
                        </div>
                      </div>
                      <!-- <p style="visibility:hidden;" class="storeText" @click="navigateTo">Continuer son magasinage</p> -->
    
                      <!-- <div v-if="invoice">
                        <v-btn v-if="invoice.balance != 0 && loading == false && invoice.paymentTypeId == 0 && !invoice.paidOn" class="mt-5 orderBtn clicker">
                          <h2 class="orderBtnTitle"  @click="payInvoice()">Payer</h2>
                        </v-btn>
                        <div v-if="invoice.paymentTypeId != 0 || loading == true">
                          <h2  class="sectionTitle" style="text-align: center;">En traitement</h2>
                          <v-progress-linear color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                          <p class="" style="font-size: small; cursor: pointer; text-align: center; color: rgb(var(--primary));" @click="cancelInvoice()">Recommencer le paiement</p>
                        </div>
                      </div> -->
                      
                  </div>
              </div>
          </div>
        </v-col>
      </v-row>
    </div>
    </section>
    <Footer/>
    <v-dialog v-model="creditCardDialog" persistent>
      <v-card >
        <v-toolbar style="background-color:rgb(var(--secondary)); color: white; padding-left: 20px !important;">
          <v-col>Information de la carte de crédit</v-col>
          <v-col style="max-width: 10%;"><v-btn style="min-width: 30px;" @click="cancelCard">X</v-btn></v-col>
        </v-toolbar>
        <v-card-text style="padding: 50px !important;">
          <div >
            <v-row><v-col><v-text-field style="min-width: 350px;" v-model="creditCardInfo.CustomerId" :rules="CreditRules.customerIds" label="Courriel" ></v-text-field></v-col></v-row>
            <v-row><v-col><v-text-field style="min-width: 350px;" v-model="creditCardInfo.CustomerName" :rules="CreditRules.customerNames" label="Acheteur" ></v-text-field></v-col></v-row>
            <v-row><v-col><v-text-field style="min-width: 350px;" v-model="creditCardInfo.CreditCard.Owner" :rules="CreditRules.owners" label="Propriétaire de la carte" ></v-text-field></v-col></v-row>
            <v-row><v-col><v-text-field style="min-width: 350px;" v-model="creditCardInfo.CreditCard.Number" :counter="16" :rules="CreditRules.numbers" label="Numéro" ></v-text-field></v-col></v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col >
                    <v-text-field style="min-width: 100%;" v-model="creditCardInfo.CreditCard.ExpMonth" :counter="2" :rules="CreditRules.monthExpirations" label="MM" ></v-text-field>
                  </v-col>
                  <v-col style="padding: 0px 10px !important;">
                    <v-text-field style="min-width: 100%;" v-model="creditCardInfo.CreditCard.ExpYear" :counter="2" :rules="CreditRules.expirations" label="YY" ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field style="min-width: 100%;" v-model="creditCardInfo.CreditCard.CVV" :counter="3" :rules="CreditRules.cvvs" label="CVV" ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
              <v-col></v-col>
              <v-col><v-btn class="mt-2 orderBtn" @click="confirmCard()"><h2 class="orderBtnTitle">Confirmer</h2></v-btn></v-col>
            </v-row>
            <v-row v-if="creditCardError" class="mt-5">
              <v-col>
                <p style="color: red; text-align: center; max-width: 350px;">Une erreur s'est produite. Veuillez réessayer, et si le problème persiste, veuillez contacter l'administrateur.</p>
              </v-col>

            </v-row>

          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
    div[data-axId="invoice-grpPaymentTypeId"] label { opacity: 1 !important; }
    div[data-axId="invoice-grpPaymentTypeId"] img { height: 35px; width: auto; }
    div[data-axId="invoice-grpPaymentTypeId"] .v-messages { color: red;}
</style>
<style scoped>
    
    .h1Height {height:180px;}
    .invoiceRefTitle {font-family: Arial, Helvetica, sans-serif; font-size: 30px;}
    .clicker {cursor:pointer;}
    h3{text-align: left; margin-bottom: 5px !important;}
    p {text-align: justify; margin: 5px 0px !important;}
    .ItemsBox {position:relative; color: rgba(var(--primary)); width: auto;  height: 100%; background-color: #FFF; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); margin: 20px 20px 30px 20px !important; padding: 20px 10px 20px 10px !important;}
    .titleStyle {color: rgba(var(--primary)); font-weight: 700 !important;}
    #sectionThanks {display: hidden;}
    .itemPrice {font-size:10px; padding-left:5px !important;}
    .hr {width:75%; color: rgba(var(--primary)); display: block; margin-left: auto !important; margin-right: auto !important;}
    /* .display-in {display: inline;} */
    .subtitleSum { font-size: 36px; font-family: Ephesis; text-align: left; color: rgba(000,000,000,0.5);}
    .ItemSummary {max-width: 900px; min-width: 600px; display: block; margin-left: auto !important; margin-right: auto !important;  }
    .ItemTitle {font-weight: 700;}
    .ItemTexte {text-align: right; margin: 0px 0px 0px 0px !important;}
    .sectionTitle { font-size: 48px; font-family: Ephesis; text-align: left; color: rgba(var(--secondary),0.5);}
    .priceTitle {text-transform: uppercase;  color: rgb(var(--primary)); text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);  padding-top: 5px !important;}
    .price {text-align: right; color: rgb(var(--secondary)); text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); font-weight: 700;}
    .orderBtnTitle {font-weight: lighter; color: rgb(var(--secondary));}
    /* .orderBtnTitle:hover {color: rgb(var(--tierce));} */
    .infoItem {padding-top: 5px !important;}
    /*Summary*/
    .summary {display: block;}
    .mobile{display: none;}
    .summaryBG { position:fixed; top: 200px; height: auto; width:25%;  background-color: rgba(var(--tierce),0.8) !important; z-index: 100; border-left: 1px solid rgb(var(--secondary));}
    .price {text-align: right; color: rgb(var(--secondary)); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); font-weight: 700;}
    .orderBtn{display: block; margin-left: auto !important; margin-right: auto !important; width: auto; padding: 0px 20px !important; background-color: white; border: 1px solid white;}
    .orderBtn:hover {background-color: rgb(var(--secondary));}
    .orderBtn:hover h2 {color: rgb(var(--tierce));}

    .storeText {color: rgb(var(--secondary)); text-align: center; text-decoration: underline; cursor: pointer; margin-top: 5px !important;  }
    .storeText:hover {text-decoration: none;}
    .h2Mobile{font-family: Ephesis; font-size: 36px; color: rgb(var(--secondary));}

    @media handheld, only screen and (max-width: 600px) {
    }
    @media handheld, only screen and (max-width: 960px) {
      .ItemSummary {max-width: unset; min-width: unset;}
      .ItemsBox{margin: 0px 20px !important; padding: 5px !important;}
      .h1Height {height:auto;}
      .soustitre{font-size: 36px; padding-left: 5px !important;}
      .ItemTexte {text-align: center; margin: 0px 0px 0px 0px !important; padding: 0px !important;}
      /*Summary*/
      .summary {display: none;}
      .summaryBG { border-left: none;}
      .mobile{display: block; padding-bottom: 10px !important;}
      .priceTitle {color: rgb(var(--primary));}
      /* .orderBtnTitle {color: rgb(var(--secondary));}
      .orderBtnTitle:hover {color: rgb(var(--tierce));} */
      .storeText {color: rgb(var(--primary));}
    }
    @media handheld, only screen and (max-width: 1264px) {}

</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import Summary from '@/components/infoPayment.vue'
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';
import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';

export default {
  name: 'Event',
  mixins: [apiECommerceExtension, navigateRouteExtension],
  props: {
    summary: Object,
  },
  data: () => ({
    invoice:null,
    loading: false,
    creditCardDialog: false,
    creditCardError:false,
    paymentTypeMessage: '',
    creditCardInfo: {
      CustomerId:"",
      CustomerName:"",
      CreditCard:{ Number:null, Owner:null, CVV:null, ExpMonth:null, ExpYear:null, Exp:""}
    },
    CreditRules:{
      customerNames: [
        value => {
          if (value?.length > 0) {
            return true
          }
          return 'obligatoire'
        },
      ],
      customerIds: [
        value => {
          if (value?.length > 0) {
            return true
          }
          return 'obligatoire'
        },
      ],
      owners: [
        value => {
          if (value?.length > 4) {
            return true
          }
          return 'trop court'
        },
      ],
      numbers: [
        value => {
          value.replace(/[^0-9]/g, '');
          if (value?.length == 16) {
            return true
          }
          return 'Invalide'
        },
      ],
      expirations: [
        value => {
          value.replace(/[^0-9]/g, '');

          if (value?.length == 2) {
            return true}
          return "2 chiffres"
        },
      ],
      monthExpirations: [
        value => {
          value.replace(/[^0-9]/g, '');
          if (value?.length == 2) { if(value >= 1 && value <= 12){ return true } }
          return "2 chiffres"
        },
      ],
      cvvs: [
        value => {
          value.replace(/[^0-9]/g, '');
          if (value?.length == 3) {return true}
          return "3 chiffres"
        },
      ],
    },

  }),
  watch: {
    'invoice.paymentTypeId': function(val) { this.paymentTypeMessage = "";  },
    'creditCardInfo.CreditCard.Number': function(val) { this.creditCardInfo.CreditCard.Number = val.replace(/[^0-9]/g, ''); },
    'creditCardInfo.CreditCard.ExpMonth': function(val) { this.creditCardInfo.CreditCard.ExpMonth = val.replace(/[^0-9]/g, ''); },
    'creditCardInfo.CreditCard.ExpYear': function(val) { this.creditCardInfo.CreditCard.ExpYear = val.replace(/[^0-9]/g, ''); },
    'creditCardInfo.CreditCard.CVV': function(val) { this.creditCardInfo.CreditCard.CVV = val.replace(/[^0-9]/g, ''); },
  },
  mounted: async function () {
    let param = this.$route.params.id;
    if(!param){
      param = this.GetInvoiceRef();
      if(param){param = param.replace(/"/g,'');}else{this.$router.push('/Accueil');}
    }
    await this.readData(param);

    if(this.invoice && this.invoice.balance == 0 && this.invoice.paidOn) {
      let paramReload = this.$route.params.id;
      let cleared = await this.ClearInvoice();
      if(!cleared){
        let paramReload = this.GetInvoiceRef();
      }

      if(!paramReload){ this.$router.push('/Accueil'); }

    }
  },
methods:{
    readData: async function(param){
      let data = await this.GetInvoice(param);
      if(data){
        if(this.creditCardInfo) {
          this.creditCardInfo.CustomerName = data.accountName;
          if (data.comment) {
            let emails = data.comment.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
            if (emails && Array.isArray(emails) && emails.length > 0) {
              this.creditCardInfo.CustomerId = emails[0];
            }
          }
        }
        if(data.paymentTypeId != 0){
          this.loading = true;
        }
        //console.log(this.creditCardInfo);
      }

      this.invoice = data;
    },
    payInvoice: async function(){

      if(this.invoice.paymentTypeId == 7) {

        this.loading = true;

        let urlToPay = '';
        if(this.invoice.uId){ urlToPay = await this.PaypalInit(this.invoice.uId); }
        if(urlToPay != "") {
          // window.open(urlToPay, '_blank');
          // this.$router.push('/Accueil');
          window.location.href = urlToPay;
        }
      } else if (this.invoice.paymentTypeId == 3) {
        this.creditCardDialog = true;
      } else { this.paymentTypeMessage = "Obligatoire";}

    },
    confirmCard: async function(){
      let valid = true;
      this.creditCardError = false;

      if(this.creditCardInfo.CreditCard){
        if(!this.creditCardInfo.CustomerId || this.creditCardInfo.CustomerId.length < 1 ) { valid = false; }
        else if(!this.creditCardInfo.CustomerName || this.creditCardInfo.CustomerName.length < 1 ) { valid = false;  }
        else if(!this.creditCardInfo.CreditCard.Owner || this.creditCardInfo.CreditCard.Owner.length < 5 ) { valid = false; }
        else if(!this.creditCardInfo.CreditCard.Number || this.creditCardInfo.CreditCard.Number.length !== 16 ) { valid = false; }
        else if(!this.creditCardInfo.CreditCard.ExpMonth || this.creditCardInfo.CreditCard.ExpMonth.length !== 2 ) { valid = false; }
        else if(!this.creditCardInfo.CreditCard.ExpYear || this.creditCardInfo.CreditCard.ExpYear.length !== 2 ) { valid = false; }
        else if(!this.creditCardInfo.CreditCard.CVV || this.creditCardInfo.CreditCard.CVV.length !== 3 ) { valid = false; }
      } else { valid = false;}

      if(valid == true) {
        this.creditCardDialog = false;
        this.loading = true;
        let err = await this.CreditCardPaiement(this.invoice.uId,this.creditCardInfo);
        if(err) {
          this.creditCardDialog = true;
          this.creditCardError = true;
          this.loading = false;
        } else { window.location.reload(); }

        // let url = '';
        // if(this.invoice.uId){ url = await this.CreditCardPaiement(this.invoice.uId,this.creditCardInfo); }
        // if(url == true){ window.location.reload();   } else { this.creditCardDialog = true; }

      } else {
        if(this.creditCardDialog != true) {this.creditCardDialog = true;}
        this.creditCardError = true;
        this.loading = false;
      }


    },
    cancelCard: async function(){
      this.loading = false;
      this.creditCardDialog = false;
      this.creditCardError = false;
    },
    cancelInvoice: async function() {
      if(this.invoice && this.invoice.uId ) { 
        if(this.invoice.paymentTypeId == 7){
          this.$router.push('/invoices/'+ this.invoice.uId + '/paypal/canceled');
        } else { window.location.reload(); }
      }
      // let param = this.$route.params.id;
      // if(!param){ param = this.GetInvoiceRef(); param = param.replace(/"/g,'');}
      // this.$router.push('/invoices/'+ param + '/paypal/canceled');
    },
    navigateTo: function () {
      this.$router.push('/Boutique');
    },
  },
  components: {
    Header,
    Footer,
    Banner,
    Summary
  },
}
</script>